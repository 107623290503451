import React, { useState, useEffect } from 'react';
import "./events-and-shows.css";

function EventsAndShowsP() {

  return (
    <div className="events-and-shows">

      <div className="cover">
        <img src="/playgroup-images/beyond-learning/Banner 3-min.jpg" className="cover-image pg-events-and-shows-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Beyond Learning</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/well-being" className="a-nav-link ">Well-Being</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/play-at-fks" className="a-nav-link ">Play At FKS</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/events-and-festivals" className="a-nav-link a-nav-item-selected">Events &amp; Festivals</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content text-align-center">
          <span className="title">EVENTS </span>
          Over the past two decades, The FKS Force and Students have conceptualized and hosted many events, such as fairs, exhibitions, shows etc. Each year, the surplus from these shows support various social and charitable initiatives identified by the students.<br/><br/>

          Events play a pivotal role in  the FKS philosophy to build organization and management skills. They provide each student an avenue to discover, test and hone their talents and abilities.<br/><br/>

          Events instil a sense of ownership and appreciation for all. Students hence realize the importance of a team, imbibe a sense of dignity and appreciate the power of hard work.
        </div>
        <div className="section-image-container ">
          <img src="/playgroup-images/beyond-learning/3-min.png" className="section-image ef-img-1"></img>
        </div>
      </div>


      {/* <div className="section">
        <div className="section-image-container events-and-shows-image-2-container">
          <img src="/images/events-and-shows/sk_1177 future kids_.jpg" className="section-image events-and-shows-image-2"></img>
        </div>
        <div className="section-content text-align-center">
          Over the past two decades, The FKS force and students have conceptualized and hosted many events, such as, fairs, exhibitions, shows etc. Each year, the surplus from these shows support various social and charitable initiatives identified by the students.

        </div>
      </div> */}


      <div className="section">
      <div className="section-image-container festivals-image-3-container">
          <img src="/playgroup-images/beyond-learning/9-min.jpg" className="section-image events-and-shows-image-3"></img>
        </div>
        <div className="section-content text-align-center">
          <span className="title">FESTIVALS</span>
          At FKS we celebrate life in all its nuances and colours. No matter what the religion, caste, creed or economic background, we encourage the feeling of oneness of humanity and use every opportunity to bring out the joy of childhood and innocence.<br/><br/>

          Festivals are a special and most beautiful way to demonstrate ‘Unity in Diversity’ to our children – a boon of a culturally rich and vibrant country called India! FKS is a world beyond religions and faith and we welcome each festival with zeal and day-long celebrations with activities, programs and lots of good food!
        </div>
      </div>

    </div>
  );
}

export default EventsAndShowsP;
