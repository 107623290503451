import { useState, useEffect } from 'react';
import "./leadership.css";

function Leadership() {

  /*MOBILE GALLERY LOGIC */

  const [selectedImg, setSelectedImg] = useState(0)

  // // useEffect(() => {
  // //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  // //     document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
  // //   }
  // //   document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
  // // }, [selectedImg])

  // // function mobCarouselNext() {
  // //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  // //     if (selectedImg == 4) {
  // //       break;
  // //     }
  // //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  // //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
  // //     setSelectedImg(selectedImg + 1)

  // //   }
  // // }

  // // function mobCarouselPrev() {
  // //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  // //     if (selectedImg == 0) {
  // //       break;
  // //     }
  // //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  // //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
  // //     setSelectedImg(selectedImg - 1)
  // //   }
  // // }

  return (
    <div className="leadership">
      <div className="cover">
        <img src="/images/leadership/_MG_6616.jpg" className="cover-image leadership-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Beyond Learning</span>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/leadership" className="a-nav-link a-nav-item-selected">Leadership at FKS</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/sports-and-games" className="a-nav-link ">Sports, Games &amp; Physical Activities</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/events-and-festivals" className="a-nav-link ">Events &amp; Festivals</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/theatre-and-drama" className="a-nav-link">Performing Art</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/field-trips" className="a-nav-link">Field Trips</a>
          </span>
        </span>
      </div>
      <div className="section">
        <div className="section-image-container leadership-image-1-container">
          <img src="/images/leadership/_MG_1723.jpg" className="section-image"></img>
        </div>
        <div className="section-content mobile-margin text-align-center">
          <span className="content-text ">
            <span className="title">LEADERSHIP AT FKS</span>
            Our FKS children never shirk responsibility! The various activities and events designed around our students expose them to taking charge and exhibit leadership abilities in their individual capacities. Taking the lead from planning an event to execution, being accountable for things and sharing duties teaches children to be go-getters, excellent team-players, risk-takers and responsible leaders of tomorrow!
          </span>
        </div>
      </div>

      <div className="curvy-container">
        <div className="curvy-image-1-container">
          <img src="/images/leadership/_MG_1722.jpg" className="curvy-image-1 leadership-curvy-image-1" />
        </div>
        <div className="curvy-image-2-container">
          <img src="/images/leadership/_MG_4888.jpg" className="curvy-image-2 leadership-curvy-image-2" />
        </div>
        {/* <div className="curvy-image-3-container">
          <img src="./images2/about_us/Our Story/OS 7.jpg" className="curvy-image-3 our-story-curvy-image-3" />
        </div> */}
      </div>

      {/* MOBILE CAROUSEL BEGIN */}

      {/* SCREEN 1*/}

      {/* <div className="mobile-picture-gallery-container">

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/leadership/_MG_1722.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(2) }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/leadership/_MG_1722.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/leadership/_MG_4888.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/leadership/_MG_6616.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/leadership/_MG_1722.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 2*

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/leadership/_MG_4888.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/leadership/_MG_1722.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/leadership/_MG_4888.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/leadership/_MG_6616.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/leadership/_MG_1722.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 3*

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/leadership/_MG_6616.jpg" className="mobile-picture-gallery-focus-image flield-gallery-mobile-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/leadership/_MG_1722.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/leadership/_MG_4888.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/leadership/_MG_6616.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/leadership/_MG_1722.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>


      </div> */}

    </div>
  );
}

export default Leadership;
