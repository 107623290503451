import React, { useState, useEffect } from 'react';
import "./well-being.css";

function WellBeingP() {

  return (
    <div className="leadership">
      <div className="cover">
        <img src="/playgroup-images/beyond-learning/Banner 1-min.jpg" className="cover-image play-at-fks-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Beyond Learning</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/well-being" className="a-nav-link pg-mob-nav-header a-nav-item-selected">Well-Being</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/play-at-fks" className="a-nav-link pg-mob-nav-header ">Play At FKS</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/events-and-festivals" className="a-nav-link pg-mob-nav-header ">Events &amp; Festivals</a>
          </span>
        </span>
      </div>
      <div className="section">
        <div className="section-image-container ">
          <img src="/playgroup-images/beyond-learning/5.jpg" className="section-image "></img>
        </div>
        <div className="section-content mobile-margin text-align-center">
          <span className="content-text ">
            <span className="title">WELL BEING</span>
            <b>Safety - CCTVs, Railings in balconies and open areas, Child friendly Furniture</b> <br /><br />
          </span>
          From avoiding chairs that children fall as they rock them or tumble on them, to rounded edges of all furniture and no-door classrooms to safety measures in balconies and switch boards besides CCTV cameras in all common areas, there is the presence of teachers and didis all around on campus to ensure safekeeping of each child.<br /><br />
          <span className="content-text ">
            <b>Care and Guidance - Teacher to Student Ratio , Didi bhaiyas, Child under proper care </b> <br /><br />
          </span>
          In the preschools, the children need assistance in all aspects. Our didis and bhaiyas are trained to manage them. From holding their hands to talking to them. We train and explain to them why we do what we do.<br /><br />
          Teachers, Assistant teachers and didis in the classroom. 3 adults in a class of 20.<br /><br />
        </div>
      </div>


      <div className="section">
        <div className="section-content  text-align-center">
          <span className="content-text ">
            <span className="title">NUTRITION</span>
            The objective at FKS is to create a global citizen who can adapt, someone who can thrive in any situation. Kids tend to get finicky and picky often, especially with food. The FKS children come from all strata of society and cultures. Their food habits are varied. Shailaja wanted to discourage these differences and convinced parents of the need to have a common kitchen for children.<br /><br />

            Eating easily and commonly available food helps in appreciating all kinds of tastes and eating the same food together gives a sense of community.<br /><br />

            <b>Some of the favourites from the FKS Kitchen:</b><br /><br />

            Idly with Chutney| Pongal | Upma with Chutney | Sandwiches | Cornflakes | Pav Bhaji | Noodles | Pasta | Festival Specials. <br /><br />

            Lunch Meals - Pulao | Sambar rice | Roti Chole curry <br /><br />

            Our students receive well-planned, nourishing meals throughout the day. The menu is designed with the idea that a growing child, a picky palette and anxious parents are all to be taken very seriously. A well-balanced diet can also be tasty and “yummy”: proof of pudding that Mrs. Rao presents on a daily basis. In fact, her tasters are student volunteers and she takes suggestions and cooking help from the senior graders as she plans the daily menu.
            </span>
        </div>
        <div className="section-image-container pg-nutrition-last-img-container">
          <img src="/playgroup-images/beyond-learning/8-min.jpg" className="section-image pg-nutrition-last-img"></img>
        </div>
      </div>

    </div>
  );
}

export default WellBeingP;
