import { lazy, Suspense } from "react";
import { Outlet, useRoutes } from "react-router-dom";

import Nav from "./componenets/nav/nav";

//main campus- nanakramguda
import LandingPage from "./main-nanakramguda/landing/landing";
// import Nav from "./componenets/nav/nav";
import OurStory from "./main-nanakramguda/our-story/our-story";
import VisionAndMission from "./main-nanakramguda/vision-and-mission/vision-and-mission";
import FksForce from "./main-nanakramguda/fks-force/fks-force";
import BoardAndCurriculum from "./main-nanakramguda/board-and-curriculum/board-and-curriculum";
import Pedagogy from "./main-nanakramguda/pedagogy/pedagogy";
import Nutrition from "./main-nanakramguda/nutrition/nutrition";
import SportsAndGames from "./main-nanakramguda/sports-and-games/sports-and-games";
import Leadership from "./main-nanakramguda/leadership/leadership";
import EventsAndShows from "./main-nanakramguda/events-and-shows/events-and-shows";
import TheatreAndDrama from "./main-nanakramguda/theatre-and-drama/theatre-and-drama";
import FieldTrips from "./main-nanakramguda/field-trips/field-trips";
import Footer from "./componenets/footer/footer";
import ExtraActivities from "./main-nanakramguda/extra-activities/extra-activities";
import Campus from "./main-nanakramguda/campus/campus";
import ContactPopup from "./componenets/contact-popup/contact-popup";
import ContactUs from "./main-nanakramguda/contact-us/contact-us";
import PrivacyPolicy from "./main-nanakramguda/privacy-policy/privacy-policy";
import AdmissionFormChildProfile from "./main-nanakramguda/admission-form-child-profile/admin-form-profile";
import OpenHouseForm from "./main-nanakramguda/open-house-form/open-house-form";
import Main from "./main-nanakramguda/main/main";
import AdmissionFormMain from "./main-nanakramguda/admissions-form/admission-form";

//playground jubilee hills
import LandingPageP from "./playgroup-jubilee-hills/pages/landing/landing";
import OurStoryP from "./playgroup-jubilee-hills/pages/our-story/our-story";
import VisionAndMissionP from "./playgroup-jubilee-hills/pages/vision-and-mission/vision-and-mission";
import FksForceP from "./playgroup-jubilee-hills/pages/fks-force/fks-force";
import BoardAndCurriculumP from "./playgroup-jubilee-hills/pages/thematic-learning/thematic-learning";
import PedagogyP from "./playgroup-jubilee-hills/pages/learning-framework/learning-framework";
import NutritionP from "./playgroup-jubilee-hills/pages/nutrition/nutrition";
import SportsAndGamesP from "./playgroup-jubilee-hills/pages/play-at-fks/play-at-fks";
import LeadershipP from "./playgroup-jubilee-hills/pages/well-being/well-being";
import EventsAndShowsP from "./playgroup-jubilee-hills/pages/events-and-shows/events-and-shows";
import TheatreAndDramaP from "./playgroup-jubilee-hills/pages/theatre-and-drama/theatre-and-drama";
import FieldTripsP from "./playgroup-jubilee-hills/pages/field-trips/field-trips";
import ExtraActivitiesP from "./playgroup-jubilee-hills/pages/learning-activities/learning-activities";
import CampusP from "./playgroup-jubilee-hills/pages/campus/campus";
import NavP from "./playgroup-jubilee-hills/componenets/nav/nav";
import ThematicLearningP from "./playgroup-jubilee-hills/pages/thematic-learning/thematic-learning";
import LearningFrameworkP from "./playgroup-jubilee-hills/pages/learning-framework/learning-framework";
import LearningActivitiesP from "./playgroup-jubilee-hills/pages/learning-activities/learning-activities";
import WellBeingP from "./playgroup-jubilee-hills/pages/well-being/well-being";
import PlayAtFKSP from "./playgroup-jubilee-hills/pages/play-at-fks/play-at-fks";
import ContactUsP from "./playgroup-jubilee-hills/pages/contact-us/contact-us";
import AdmissionForm from "./playgroup-jubilee-hills/pages/admissions-form/admission-form";
import FooterPG from "./playgroup-jubilee-hills/componenets/footer/footer";
import AdmissionFormPlaygroupNarsingi from "./playgroup-narsingi/pages/admissions-form/admission-form";
import ContactUsPlaygroupNarsingi from "./playgroup-narsingi/pages/contact-us/contact-us";
import NavPlaygroupNarsingi from "./playgroup-narsingi/componenets/nav/nav";
import FooterPlaygroupNarsingi from "./playgroup-narsingi/componenets/footer/footer";
import CampusPlaygroupNarsingi from "./playgroup-narsingi/pages/campus/campus";
import LearningActivitiesPlaygroupNarsingi from "./playgroup-narsingi/pages/learning-activities/learning-activities";
import FieldTripsPlaygroupNarsingi from "./playgroup-narsingi/pages/field-trips/field-trips";
import TheatreAndDramaPlaygroupNarsingi from "./playgroup-narsingi/pages/theatre-and-drama/theatre-and-drama";
import EventsAndShowsPlaygroupNarsingi from "./playgroup-narsingi/pages/events-and-shows/events-and-shows";
import WellBeingPlaygroupNarsingi from "./playgroup-narsingi/pages/well-being/well-being";
import PlayAtFKSPlaygroupNarsingi from "./playgroup-narsingi/pages/play-at-fks/play-at-fks";
import NutritionPlaygroupNarsingi from "./playgroup-narsingi/pages/nutrition/nutrition";
import LearningFrameworkPlaygroupNarsingi from "./playgroup-narsingi/pages/learning-framework/learning-framework";
import ThematicLearningPlaygroupNarsingi from "./playgroup-narsingi/pages/thematic-learning/thematic-learning";
import FksForcePlaygroupNarsingi from "./playgroup-narsingi/pages/fks-force/fks-force";
import VisionAndMissionPlaygroupNarsingi from "./playgroup-narsingi/pages/vision-and-mission/vision-and-mission";
import OurStoryPlaygroupNarsingi from "./playgroup-narsingi/pages/our-story/our-story";
import LandingPagePlaygroupNarsingi from "./playgroup-narsingi/pages/landing/landing";
import Admin from "./admin";

function PlaygroupNarsingiOutlet() {
  return (
    <>
      <NavPlaygroupNarsingi />
      <Outlet />
      <FooterPlaygroupNarsingi />
    </>
  );
}

function PlaygroupJubileeHillsOutlet() {
  return (
    <>
      <NavP />
      <Outlet />
      <FooterPG />
    </>
  );
}

function MainCampusOutlet() {
  return (
    <>
      <Nav />
      <Outlet />
      <Footer />
    </>
  );
}

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <Main /> },
    { path: "/admin", element: <Admin /> },
    {
      path: "/privacy-policy",
      element: (
        <>
          <Nav />
          <PrivacyPolicy />
          <Footer />
        </>
      ),
    },
    {
      path: "/playgroup-narsingi",
      element: <PlaygroupNarsingiOutlet />,
      children: [
        {
          path: "",
          element: <LandingPagePlaygroupNarsingi />,
        },
        {
          path: "admissions",
          element: <AdmissionFormPlaygroupNarsingi />,
        },
        {
          path: "connect",
          element: <ContactUsPlaygroupNarsingi />,
        },
        {
          path: "campus",
          element: <CampusPlaygroupNarsingi />,
        },
        {
          path: "learning-activities",
          element: <LearningActivitiesPlaygroupNarsingi />,
        },
        {
          path: "field-trips",
          element: <FieldTripsPlaygroupNarsingi />,
        },
        {
          path: "theatre-and-drama",
          element: <TheatreAndDramaPlaygroupNarsingi />,
        },
        {
          path: "events-and-festivals",
          element: <TheatreAndDramaPlaygroupNarsingi />,
        },
        {
          path: "well-being",
          element: <WellBeingPlaygroupNarsingi />,
        },
        {
          path: "play-at-fks",
          element: <PlayAtFKSPlaygroupNarsingi />,
        },
        {
          path: "nutrition",
          element: <NutritionPlaygroupNarsingi />,
        },
        {
          path: "learning-framework",
          element: <LearningFrameworkPlaygroupNarsingi />,
        },
        {
          path: "thematic-learning",
          element: <ThematicLearningPlaygroupNarsingi />,
        },
        {
          path: "fks-force",
          element: <FksForcePlaygroupNarsingi />,
        },
        {
          path: "vision-and-mission",
          element: <VisionAndMissionPlaygroupNarsingi />,
        },
        {
          path: "our-story",
          element: <OurStoryPlaygroupNarsingi />,
        },
      ],
    },
    {
      path: "/playgroup-jubileehills",
      element: <PlaygroupJubileeHillsOutlet />,
      children: [
        {
          path: "",
          element: <LandingPageP />,
        },
        {
          path: "admissions",
          element: <AdmissionForm />,
        },
        {
          path: "connect",
          element: <ContactUsP />,
        },
        {
          path: "campus",
          element: <CampusP />,
        },
        {
          path: "learning-activities",
          element: <LearningActivitiesP />,
        },
        {
          path: "field-trips",
          element: <FieldTripsP />,
        },
        {
          path: "theatre-and-drama",
          element: <TheatreAndDramaP />,
        },
        {
          path: "events-and-festivals",
          element: <EventsAndShowsP />,
        },
        {
          path: "well-being",
          element: <WellBeingP />,
        },
        {
          path: "play-at-fks",
          element: <PlayAtFKSP />,
        },
        {
          path: "nutrition",
          element: <NutritionP />,
        },
        {
          path: "learning-framework",
          element: <LearningFrameworkP />,
        },
        {
          path: "thematic-learning",
          element: <ThematicLearningP />,
        },
        {
          path: "fks-force",
          element: <FksForceP />,
        },
        {
          path: "vision-and-mission",
          element: <VisionAndMissionP />,
        },
        {
          path: "our-story",
          element: <OurStoryP />,
        },
      ],
    },
    {
      path: "/main-campus",
      element: <MainCampusOutlet />,
      children: [
        {
          path: "",
          element: <LandingPage />,
        },
        {
          path: "admissions",
          element: <AdmissionFormMain />,
        },
        {
          path: "connect",
          element: <ContactUs />,
        },
        {
          path: "campus",
          element: <Campus />,
        },
        {
          path: "extra-activities",
          element: <ExtraActivities />,
        },
        {
          path: "field-trips",
          element: <FieldTrips />,
        },
        {
          path: "theatre-and-drama",
          element: <TheatreAndDrama />,
        },
        {
          path: "events-and-festivals",
          element: <EventsAndShows />,
        },
        {
          path: "leadership",
          element: <Leadership />,
        },
        {
          path: "sports-and-games",
          element: <SportsAndGames />,
        },
        {
          path: "well-being",
          element: <WellBeingP />,
        },
        {
          path: "play-at-fks",
          element: <PlayAtFKSP />,
        },
        {
          path: "nutrition",
          element: <Nutrition />,
        },
        {
          path: "pedagogy",
          element: <Pedagogy />,
        },
        {
          path: "board-and-curriculum",
          element: <BoardAndCurriculum />,
        },
        {
          path: "fks-force",
          element: <FksForce />,
        },
        {
          path: "vision-and-mission",
          element: <VisionAndMission />,
        },
        {
          path: "our-story",
          element: <OurStory />,
        },
      ],
    },
  ]);
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          loading
        </div>
      }
    >
      {routes}
    </Suspense>
  );
};

export default AppRoutes;
