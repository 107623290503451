import { Table } from "react-bootstrap";
import AdmissionFormMain from "../admissions-form/admission-form";
import "./contact-us.css";

function ContactUs() {
  /*useEffect(() => {
        const existingScript1 = document.getElementById("googleMaps");

        if (!existingScript1) {
            const script1 = document.createElement("script");
            script1.src =
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyCr7Xc7zyE1sOBe3GmLouPlp6P1_KrI9so&libraries=places";
            script1.id = "googleMaps";
            script1.async = true;

            document.body.appendChild(script1);

            script1.onload = () => {
                initMap1();
            };
        }

        if (existingScript1) initMap1();
    }, []);


    function initMap1() {
        var google1 = window.google;
        const myLatLng1 = { lat: 17.405980251143284, lng: 78.34963433422631 };
        const map1 = new google1.maps.Map(document.getElementById("map2"), {
            zoom: 16,
            center: myLatLng1
        });
        new google1.maps.Marker({
            position: myLatLng1,
            map1,
            title: "The Future Kids School"
        });
    }*/

  return (
    <div className="campus">
      <div className="cover">
        <img
          src="/images/campus/Contact us Cover.JPG"
          className="cover-image"
        />
      </div>
      <div className="contact-us-dual-section">
        <div className="contact-us-dual-section-left">
          <div className="section">
            <div className="section-content contact-us-section-content">
              <span className="title">CONTACT US</span>
              <span className="content-text">
                <b>The Future Kid's School</b>
                <br />
                Puppal Guda, Rajendranagar Mandal, RR Dist, Hyderabad, Telangana
                500075
                <br />
                <br />
                <img
                  src="/icons8-phone-24.png"
                  className="pg-contact-us-icon"
                />
                <a href="tel:+91 9959 319 742" className="phone-text">
                  +91 9959 319 742
                </a>
                <br />
                <img
                  src="/icons8-phone-24.png"
                  className="pg-contact-us-icon"
                />
                <a href="tel:+91 9959 319 742" className="phone-text">
                  +91 9154 219 742
                </a>
                <br />
                <br />
                <img src="/icons8-mail-50.png" className="pg-contact-us-icon" />
                <a
                  href="mailto:connect@thefks.in"
                  style={{ textDecoration: "none", color: "#8A906B" }}
                >
                  connect@thefks.in
                </a>
                <br />
                <img src="/icons8-mail-50.png" className="pg-contact-us-icon" />
                <a
                  href="mailto:admissions@thefks.in"
                  style={{ textDecoration: "none", color: "#8A906B" }}
                >
                  admissions@thefks.in
                </a>
              </span>
            </div>
          </div>

          <div className="section">
            <div className="section-content contact-us-section-content">
              <span className="title">Admission Age Criteria (2025-26)</span>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Year of Birth</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Grade 1</td>
                    <td>2019</td>
                  </tr>
                  <tr>
                    <td>Grade 2</td>
                    <td>2018</td>
                  </tr>
                  <tr>
                    <td>Grade 3</td>
                    <td>2017</td>
                  </tr>
                  <tr>
                    <td>Grade 4</td>
                    <td>2016</td>
                  </tr>
                  <tr>
                    <td>Grade 5</td>
                    <td>2015</td>
                  </tr>
                  <tr>
                    <td>Grade 6</td>
                    <td>2014</td>
                  </tr>
                  <tr>
                    <td>Grade 7</td>
                    <td>2013</td>
                  </tr>
                  <tr>
                    <td>Grade 8</td>
                    <td>2012</td>
                  </tr>
                  <tr>
                    <td>Grade 9</td>
                    <td>2011</td>
                  </tr>
                  <tr>
                    <td>Grade 11</td>
                    <td>2010</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="contact-us-dual-section-right admissions-form-section">
          <AdmissionFormMain />
        </div>
      </div>
      <a
        href="https://www.google.com/maps/place/The+Future+Kid's+School/@17.4046598,78.3495526,15z/data=!4m5!3m4!1s0x0:0xe383f27f7987ae8c!8m2!3d17.4046598!4d78.3495526"
        target="_blank" rel="noreferrer"
      >
        <div className="contact-map-container">
          <img src="/contact-page-map.png" className="contact-page-map" />
        </div>
      </a>
      {/* <div className="contact-map" id="map2"></div> */}
    </div>
  );
}

export default ContactUs;
