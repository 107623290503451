import "./landing.css";
import React, { useState, useEffect } from 'react';
import Footer from "../../componenets/footer/footer";

function LandingPageP() {

  //main carousel 
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('carousel-image-container').length; i++) {
      document.getElementsByClassName('carousel-image-container')[i].style.display = "none";
    }
    document.getElementsByClassName('carousel-image-container')[current].style.display = "block";
  }, [current])

  function mainCarouselNext() {
    if (current < 2) {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[current + 1].style.display = "block";
      setCurrent(current + 1)
      console.log(current);
    }
    else {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[0].style.display = "block";
      setCurrent(0);

    }
  }

  function mainCarouselPrev() {
    if (current > 0) {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[current - 1].style.display = "block";
      setCurrent(current - 1)
      console.log(current);
    }
    else {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[2].style.display = "block";
      setCurrent(2);

    }
  }

  // useEffect(() => {
  //   if (current < 4) {
  //     setTimeout(() => {
  //       setCurrent(current + 1);
  //       for (var i = 0; i < document.getElementsByClassName('carousel-image-container').length; i++) {
  //         document.getElementsByClassName('carousel-image-container')[i].style.display = "none"
  //       }
  //       document.getElementsByClassName('carousel-image-container')[current].style.display = "block"
  //     }, 3000)
  //   }
  //   else {
  //     setTimeout(() => {
  //       setCurrent(0)
  //       for (var i = 0; i < document.getElementsByClassName('carousel-image-container').length; i++) {
  //         document.getElementsByClassName('carousel-image-container')[i].style.display = "none"
  //       }
  //       document.getElementsByClassName('carousel-image-container')[0].style.display = "block"
  //     }, 3000)
  //   }
  // }, [current])



  const [selectedEvent, setSelectedEvent] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('events-carousel-item').length; i++) {
      document.getElementsByClassName('events-carousel-item')[i].style.display = "none";
    }
    document.getElementsByClassName('events-carousel-item')[selectedEvent].style.display = "flex";
  }, [selectedEvent])

  function eventsCarouselNext() {
    for (var i = 0; i < document.getElementsByClassName('events-carousel-item').length; i++) {
      if (selectedEvent == 3) {
        break;
      }
      document.getElementsByClassName('events-carousel-item')[selectedEvent].style.display = "none";
      document.getElementsByClassName('events-carousel-item')[selectedEvent + 1].style.display = "flex";
      setSelectedEvent(selectedEvent + 1)

    }
  }

  function eventsCarouselPrev() {
    for (var i = 0; i < document.getElementsByClassName('events-carousel-item').length; i++) {
      if (selectedEvent == 0) {
        break;
      }
      document.getElementsByClassName('events-carousel-item')[selectedEvent].style.display = "none";
      document.getElementsByClassName('events-carousel-item')[selectedEvent - 1].style.display = "flex";
      setSelectedEvent(selectedEvent - 1)

    }
  }


  const [selected, setSelected] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('highlights-carousel-item').length; i++) {
      document.getElementsByClassName('highlights-carousel-item')[i].style.display = "none";
    }
    document.getElementsByClassName('highlights-carousel-item')[selected].style.display = "flex";
  }, [selected])

  function carouselNext() {
    for (var i = 0; i < document.getElementsByClassName('highlights-carousel-item').length; i++) {
      if (selected == 3) {
        break;
      }
      document.getElementsByClassName('highlights-carousel-item')[selected].style.display = "none";
      document.getElementsByClassName('highlights-carousel-item')[selected + 1].style.display = "flex";
      setSelected(selected + 1)

    }
  }

  function carouselPrev() {
    for (var i = 0; i < document.getElementsByClassName('highlights-carousel-item').length; i++) {
      if (selected == 0) {
        break;
      }
      document.getElementsByClassName('highlights-carousel-item')[selected].style.display = "none";
      document.getElementsByClassName('highlights-carousel-item')[selected - 1].style.display = "flex";
      setSelected(selected - 1)

    }
  }


  /*testimonial carousel */

  const [currentTestimonial, setCurrentTestimonail] = useState(0)

  function testimonialCarouselNext() {
    for (var i = 0; i < document.getElementsByClassName('testimonial-container').length; i++) {
      document.getElementsByClassName('testimonial-container')[i].style.display = "none";
    }

    if (currentTestimonial < 2) {
      document.getElementsByClassName('testimonial-container')[currentTestimonial + 1].style.display = "block"
      setCurrentTestimonail(currentTestimonial + 1)
    }
    else {
      document.getElementsByClassName('testimonial-container')[0].style.display = "block"
      setCurrentTestimonail(0)
    }
  }

  function testimonialCarouselPrev() {
    for (var i = 0; i < document.getElementsByClassName('testimonial-container').length; i++) {
      document.getElementsByClassName('testimonial-container')[i].style.display = "none";
    }

    if (currentTestimonial > 0) {
      document.getElementsByClassName('testimonial-container')[currentTestimonial - 1].style.display = "block"
      setCurrentTestimonail(currentTestimonial - 1)
    }
    else {
      document.getElementsByClassName('testimonial-container')[2].style.display = "block"
      setCurrentTestimonail(2)
    }

  }




  return (
    <div className="landing">

      <div className="carousel">
        <div className="carousel-image-container">
          <img src="/playgroup-images/home/Banner 1-min.jpg" className="carousel-image carousel-image-1" />
          <span className="carousel-text pg-banner-text">
            FKS gives the child an education as a “child would want it,” where they are ‘schooled for a journey called life!"
                    </span>
          <div className="main-carousel-button-container pg-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                {/* <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} /> */}
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>
        </div>
        <div className="carousel-image-container">
          <img src="/playgroup-images/home/Banner 2-min.jpg" className="carousel-image" />
          <span className="carousel-text pg-banner-text pg-banner-2-text">We believe in organic growth from within because such a change carries the power to transform self and others.
          </span>
          <div className="main-carousel-button-container pg-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                {/* <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} /> */}
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>

        </div>
        {/* <div className="carousel-image-container">
          <img src="/images/landing-page/Slide 3.jpg" className="carousel-image carousel-image-3" />
          <span className="carousel-text">We fuel in them the willingness to learn and zest to take on challenges. Thus, developing in them the quest for knowledge and saying yes to opportunities.
          </span>
          <div className="main-carousel-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} />
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>

        </div> */}
        <div className="carousel-image-container">
          <img src="/playgroup-images/home/Banner 3-min.jpg" className="carousel-image pg-banner-3" />
          <span className="carousel-text pg-banner-text">We work towards making the future generations responsible, work with equanimity and integrity; Creating a strong foundation where they balance head and heart.
          </span>
          <div className="main-carousel-button-container pg-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                {/* <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} /> */}
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>

        </div>
      </div>

      {/* GRID MOBILE DEVICES */}

      <div className="grid-mobile-devices">
        <div className="section">
          <div className="section-content box-section">
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 1-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Thematic Learning Environment</b></span>
              <span className="box-text">We lay foundations for their future learning path through the three years of play school. We work at enhancing their skills through a multi sensory approach in a thematic learning environment.</span>

              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/thematic-learning">Know More</a>
              </div>

            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 2-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Learning Framework</b></span>
              <span className="box-text">The mentor and the mentee walk hand in hand in the immersive learning environment. The learning modules are planned to cater to individual needs while ensuring the enhancement of the entire group.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/learning-framework">Know More</a>
              </div>
            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 3-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Celebrations at FKS</b></span>
              <span className="box-text">At FKS we celebrate life in all its nuances and colors. No matter what the religion, caste, creed or economic backgroup, we encourange the feeling of oneness of humanity and use every opportunity to bring out the joy of childhood and innocence.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/events-and-festivals">Know More</a>
              </div>

            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 4-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>'Child-first' Philosophy</b></span>
              <span className="box-text">Children have always been the center of our ever-expanding universe - where they are "Schooled for a journey called life!" The FKS fraternity is committed to creating a milieu of stimuli, inquisitiveness and solidarity among students.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/vision-and-mission">Know More</a>
              </div>

            </div>
           

          </div>
        </div>
      </div>

      {/* GRID LARGE DEVICES */}

      <div className="grid-large-devices">
        <div className="section">
          <div className="section-content box-section">
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 1-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Thematic Learning Environment</b></span>
              <span className="box-text">We lay foundations for their future learning path through the three years of play school. We work at enhancing their skills through a multi sensory approach in a thematic learning environment.</span>

              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/thematic-learning">Know More</a>
              </div>

            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 2-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Learning Framework</b></span>
              <span className="box-text">The mentor and the mentee walk hand in hand in the immersive learning environment. The learning modules are planned to cater to individual needs while ensuring the enhancement of the entire group.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/learning-framework">Know More</a>
              </div>
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Celebrations at FKS</b></span>
              <span className="box-text">At FKS we celebrate life in all its nuances and colors. No matter what the religion, caste, creed or economic backgroup, we encourange the feeling of oneness of humanity and use every opportunity to bring out the joy of childhood and innocence.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/events-and-festivals">Know More</a>
              </div>

            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 3-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>'Child-first' Philosophy</b></span>
              <span className="box-text">Children have always been the center of our ever-expanding universe - where they are "Schooled for a journey called life!" The FKS fraternity is committed to creating a milieu of stimuli, inquisitiveness and solidarity among students.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/vision-and-mission">Know More</a>
              </div>

            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 4-min.jpg" />
            </div>

          </div>
        </div>
      </div>

      {/* GRID SMALL LAPTOPS AND TABLETS*/}

      <div className="grid-small-laptops">
        <div className="section">
          <div className="section-content box-section">
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 1-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Thematic Learning Environment</b></span>
              <span className="box-text">We lay foundations for their future learning path through the three years of play school. We work at enhancing their skills through a multi sensory approach in a thematic learning environment.</span>

              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/thematic-learning">Know More</a>
              </div>

            </div>
            <div className="box box-content">
              <span className="box-title"><b>Learning Framework</b></span>
              <span className="box-text">The mentor and the mentee walk hand in hand in the immersive learning environment. The learning modules are planned to cater to individual needs while ensuring the enhancement of the entire group.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/learning-framework">Know More</a>
              </div>
            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 2-min.jpg" />
            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 3-min.jpg" />
            </div>
            <div className="box box-content">
              <span className="box-title"><b>Celebrations at FKS</b></span>
              <span className="box-text">At FKS we celebrate life in all its nuances and colors. No matter what the religion, caste, creed or economic backgroup, we encourange the feeling of oneness of humanity and use every opportunity to bring out the joy of childhood and innocence.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/events-and-festivals">Know More</a>
              </div>

            </div>
            <div className="box box-content">
              <span className="box-title"><b>'Child-first' Philosophy</b></span>
              <span className="box-text">Children have always been the center of our ever-expanding universe - where they are "Schooled for a journey called life!" The FKS fraternity is committed to creating a milieu of stimuli, inquisitiveness and solidarity among students.</span>
              <div className="pg-box-link-container">
                <a className="box-link" href="/playgroup-jubileehills/vision-and-mission">Know More</a>
              </div>

            </div>
            <div className="box">
              <img className="box-image" src="/playgroup-images/home/Pic 4-min.jpg" />
            </div>

          </div>
        </div>
      </div>

      <div className="section">
        <div className="section-content testimonial-section">
          <span className="title">PARENTS EXPERIENCE</span>
          <div className="testimonial-container">
            {/* <span className="testimonial-image-container"><img className="testimonial-image" src="/playgroup-images/testimonials/Parent 1-min.png" /></span> */}
            <span className="testimonial-text">"We are very satisfied with the way online classes are being held for Pre-KG. Thank you to all the teachers for being observant and making learning fun for the kids."
            <span className="testimonial-credit"><b>~Sangeeta - Evani Yumjala, Pre-KG</b></span>
              <div className="testimonial-carousel-button-container">
                <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon pg-testimonial-carousel-icon" onClick={e => { e.preventDefault(); testimonialCarouselPrev() }} />
                <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon pg-testimonial-carousel-icon" onClick={e => { e.preventDefault(); testimonialCarouselNext() }} />
              </div>
            </span>
          </div>
          <div className="testimonial-container">
            {/* <span className="testimonial-image-container"><img className="testimonial-image" src="/playgroup-images/testimonials/Parent 2-min.png" /></span> */}
            <span className="testimonial-text">"Thank you to all the teachers, support staff and bhaiyas who adapted so well to this period of crisis and came out shining. After some expected initial hiccups, I was glad to see Giaan enjoying his classes and looking forward to all the activities and interactions. Exceptional efforts by all the teachers to engage kids over zoom calls and making sure no one is missed, was truly wonderful to watch. Also, thank you to Vasu ma'am for taking any feedback that we shared in a positive way and even implementing it!"
            <span className="testimonial-credit"><b>~Shruti Ajmera Reddy - Giaan Ajmera, LKG</b></span>
              <div className="testimonial-carousel-button-container">
                <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon pg-testimonial-carousel-icon" onClick={e => { e.preventDefault(); testimonialCarouselPrev() }} />
                <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon pg-testimonial-carousel-icon" onClick={e => { e.preventDefault(); testimonialCarouselNext() }} />
              </div>
            </span>
          </div>
          <div className="testimonial-container">
            {/* <span className="testimonial-image-container"><img className="testimonial-image" src="/playgroup-images/testimonials/Parent 3-min.png" /></span> */}
            <span className="testimonial-text">"We would like to take this opportunity to thank all the aunties for delivering a fabulous experience for the children. It was clearly evident that the aunties were quick to adapt themselves to the need of the hour and found innovative ways to keep the children hooked on to the online sessions. We could clearly see Advaith enjoying the sessions and at the same time learning a lot.
            I would also like to mention how the aunties were also very approachable for us to have an open discussion on Advaith’s progress and lend us a patient ear when we wanted to discuss our concerns. We look forward to a long association with FKS and are very happy about our choice of school."
            <span className="testimonial-credit"><b>~Priyanjali &amp; Ajay - Advait Krishna, UKG</b></span>
              <div className="testimonial-carousel-button-container">
                <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon pg-testimonial-carousel-icon" onClick={e => { e.preventDefault(); testimonialCarouselPrev() }} />
                <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon pg-testimonial-carousel-icon" onClick={e => { e.preventDefault(); testimonialCarouselNext() }} />
              </div>
            </span>
          </div>
        </div>
      </div>

      {/* <div className="section">
        <div className="section-content fks-tribune-section-content">
          <span className="title">FKS TRIBUNE</span>
          <div className="tribune-item-row">
            <a href="https://fkstribune.in/magazine/Html/Cultural.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-cultural.jpeg" className="tribune-item-image" />
              <label className="tribune-label">CULTURAL</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Literary.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-literary.jpeg" className="tribune-item-image" />
              <label className="tribune-label">LITERARY</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Human_Gemstone.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-human-gemstone.jpeg" className="tribune-item-image" />
              <label className="tribune-label">HUMAN GEMSTONES</label>
            </a>
          </div>
          <div className="tribune-item-row">
            <a href="https://fkstribune.in/magazine/Html/FKS_News.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-news.jpeg" className="fks-tribune-item-img" className="tribune-item-image" />
              <label className="tribune-label">FKS NEWS</label>
            </a>
            <a href="https://fkstribune.in/magazine/Games/Games.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-fun-games.png" className="tribune-item-image" />
              <label className="tribune-label">FUN GAMES</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Fun_Facts.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-fun-facts.jpg" className="tribune-item-image" />
              <label className="tribune-label">FUN FACTS</label>
            </a>
          </div>

          <div className="tribune-item-row-mob">
            <a href="https://fkstribune.in/magazine/Html/Cultural.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-cultural.jpeg" className="tribune-item-image" />
              <label className="tribune-label">CULTURAL</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Literary.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-literary.jpeg" className="tribune-item-image" />
              <label className="tribune-label">LITERARY</label>
            </a>
          </div>

          <div className="tribune-item-row-mob">
            <a href="https://fkstribune.in/magazine/Html/Human_Gemstone.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-human-gemstone.jpeg" className="tribune-item-image" />
              <label className="tribune-label">HUMAN GEMSTONES</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/FKS_News.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-news.jpeg" className="fks-tribune-item-img" className="tribune-item-image" />
              <label className="tribune-label">FKS NEWS</label>
            </a>
          </div>

          <div className="tribune-item-row-mob">
            <a href="https://fkstribune.in/magazine/Games/Games.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-fun-games.png" className="tribune-item-image" />
              <label className="tribune-label">FUN GAMES</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Fun_Facts.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-fun-facts.jpg" className="tribune-item-image" />
              <label className="tribune-label">FUN FACTS</label>
            </a>
          </div>
        </div>
      </div> */}


      <div className="section">
        <div className="section-content landing-section-content news-and-highlights-container">
          <span className="title">EVENTS 2020-2021</span>
          <div className="events-carousel-container">

            {/* Carousel item - 1  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/4Vse_xpdj0w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelectedEvent(3) }} />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); eventsCarouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" src="https://www.youtube.com/embed/0NNNSyuuzjA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/xS7djhBbDs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>


            {/* Carousel item - 2  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/0NNNSyuuzjA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); eventsCarouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); eventsCarouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/xS7djhBbDs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
            </div>

            {/* Carousel item - 3  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/xS7djhBbDs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); eventsCarouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); eventsCarouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/J6DVgz5lcBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/4Vse_xpdj0w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
            </div>

            {/* Carousel item - 4  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/J6DVgz5lcBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); eventsCarouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelectedEvent(0) }} />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/4Vse_xpdj0w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/0NNNSyuuzjA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          {/* <div className="yt-video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/czkeZ9iUIiI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="yt-video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/jcFnbhXvCWs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="yt-video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div> */}
        </div >
      </div >

      < div className="section" >
        <div className="section-content landing-section-content news-and-highlights-container">
          <span className="title">FROM THE FOUNDERS DESK</span>
          <div className="highlights-carousel-container">

            {/* Carousel item - 1  */}

            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/r6HoUkqsibY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelected(4) }} />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/0utH05N7j4w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>


            {/* Carousel item - 2  */}

            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/0utH05N7j4w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
              </div>
            </div>

            {/* Carousel item - 3  */}


            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/DLKSWYdKTRI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
              </div>
            </div>

            {/* Carousel item - 4 */}


            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/DLKSWYdKTRI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/r6HoUkqsibY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
              </div>
            </div>


            {/* Carousel item - 5 */}

            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/DLKSWYdKTRI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelected(0) }} />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/r6HoUkqsibY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>


          </div>

        </div>
      </div >



    </div >
  );
}

export default LandingPageP;
