import { useState, useEffect } from 'react';

import "./sports-and-games.css";


function SportsAndGames() {

  // const [selected, setSelected] = useState(0)

  // useEffect(() => {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     document.getElementsByClassName('sports-carousel-item')[i].style.display = "none";
  //   }
  //   document.getElementsByClassName('sports-carousel-item')[selected].style.display = "flex";
  // }, [selected])

  // function carouselNext() {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     if (selected == 7) {
  //       break;
  //     }
  //     document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
  //     document.getElementsByClassName('sports-carousel-item')[selected + 1].style.display = "flex";
  //     setSelected(selected + 1)

  //   }
  // }

  // function carouselPrev() {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     if (selected == 0) {
  //       break;
  //     }
  //     document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
  //     document.getElementsByClassName('sports-carousel-item')[selected - 1].style.display = "flex";
  //     setSelected(selected - 1)
  //   }
  // }

  // /*MOBILE GALLERY LOGIC */

   const [selectedImg, setSelectedImg] = useState(0)

   useEffect(() => {
     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
       document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
    }
     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
   }, [selectedImg])

   function mobCarouselNext() {
     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
       if (selectedImg == 7) {
         break;
       }
       document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
       document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
       setSelectedImg(selectedImg + 1)

     }
   }

   function mobCarouselPrev() {
     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
       if (selectedImg == 0) {
         break;
      }
      document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
       document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
       setSelectedImg(selectedImg - 1)
     }
   }



  return (
    <div className="sports-and-games">

      <div className="cover">
        <img src="/images/sports-and-games/_MG_0452.jpg" className="cover-image sports-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Beyond Learning</span>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/leadership" className="a-nav-link">Leadership at FKS</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/sports-and-games" className="a-nav-link a-nav-item-selected">Sports, Games &amp; Physical Activities</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/events-and-festivals" className="a-nav-link ">Events &amp; Festivals</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/theatre-and-drama" className="a-nav-link">Performing Art</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/field-trips" className="a-nav-link">Field Trips</a>
          </span>
        </span>
      </div>


      <div className="section">
        <div className="section-content">
          <div className="content-text text-align-center">
            <span className="title">Sports And Games</span>

            FKS emphasizes on at least one hour of sports or games per day for
  children of every age. We believe that beyond just the physical fitness,
  sports increase our self-esteem and ignite our minds to be more
  receptive and alert – leading to overall personality development. Our
  strong-willed children learn to win with humility and lose with grace, to
  work as a team and to take risks – and well-designed sport sessions
  at FKS are playing a major role in adding shine to their character.
          </div>
        </div>
        <div className="section-image-container sports-and-games-image-1-container">
          <img src="/images/sports-and-games/Sports.JPG" className="section-image sports-and-games-image-1"></img>
        </div>
      </div>


      <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Various Sports and Activities at FKS</span>
            <div className="icons-row">
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/volley-ball.png" className="icon-image" />
                <span className="icon-description">Volley Ball</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/table-tennis.png" className="icon-image" />
                <span className="icon-description">Table Tennis</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/skating.png" className="icon-image" />
                <span className="icon-description">Skating</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/cricket.png" className="icon-image" />
                <span className="icon-description">Cricket</span>
              </div>
            </div>

            <div className="icons-row">
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/athletics.png" className="icon-image" />
                <span className="icon-description">Athletics</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/basketball.png" className="icon-image" />
                <span className="icon-description">Basketball</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/karate.png" className="icon-image" />
                <span className="icon-description">Karate</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/Iso.png" className="icon-image small-icon" />
                <span className="icon-description">Isometrics</span>
              </div>
            </div>

            <div className="icons-row">
              <div className="icon-container ">
                <img src="/icons/carrom.png" className="icon-image" />
                <span className="icon-description">Carrom</span>
              </div>
              <div className="icon-container ">
                <img src="/icons/football.png" className="icon-image" />
                <span className="icon-description">Football</span>
              </div>
              <div className="icon-container ">
                <img src="/icons/chess.png" className="icon-image " />
                <span className="icon-description">Chess</span>
              </div>
            </div>

            {/* MOBILE LAYOUT */}

            <div className="icons-row-mobile">
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/volley-ball.png" className="icon-image" />
                <span className="icon-description">Volley Ball</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/table-tennis.png" className="icon-image" />
                <span className="icon-description">Table Tennis</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/skating.png" className="icon-image" />
                <span className="icon-description">Skating</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/skating.png" className="icon-image" />
                <span className="icon-description">Skating</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/cricket.png" className="icon-image" />
                <span className="icon-description">Cricket</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/athletics.png" className="icon-image" />
                <span className="icon-description">Athletics</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/basketball.png" className="icon-image" />
                <span className="icon-description">Basketball</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/karate.png" className="icon-image" />
                <span className="icon-description">Karate</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/Iso.png" className="icon-image small-icon" />
                <span className="icon-description">Isometrics</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/carrom.png" className="icon-image" />
                <span className="icon-description">Carrom</span>
              </div>
              <div className="icon-container icon-contaniner-small">
                <img src="/icons/football.png" className="icon-image" />
                <span className="icon-description">Football</span>
              </div>
              <div className="icon-container ">
                <img src="/icons/chess.png" className="icon-image " />
                <span className="icon-description">Chess</span>
              </div>
            </div>
          </span>
        </div>
      </div>

      <div className="curvy-container">
        <div className="curvy-image-1-container">
          <img src="/images/sports-and-games/101_3729.jpg" className="curvy-image-1 leadership-curvy-image-1" />
        </div>
        <div className="curvy-image-2-container">
          <img src="/images/sports-and-games/101_2815.jpg" className="curvy-image-2 sports-and-games-curvy-img-2" />
        </div>
        <div className="curvy-image-3-container">
          <img src="/images/sports-and-games/102_5618.jpg" className="curvy-image-3 our-story-curvy-image-3" />
        </div> 
      </div>



      {/* <div className="curvy-container new">
        {/* CAROUSEL - 1 
        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/DSCF1522.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/_MG_9582.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(7) }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/101_2815.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 2 

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/_MG_9582.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/101_2815.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/101_2945.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 3 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/101_2815.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/101_2945.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/101_3599.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 4 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/101_2945.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/101_3599.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/101_3729.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 5 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/101_3599.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/101_3729.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/102_5618.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 6 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/101_3729.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/102_5618.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/DSCF0996.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 7 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/102_5618.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/DSCF0996.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/DSCF1522.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 8 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/sports-and-games/DSCF0996.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/sports-and-games/DSCF1522.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(0) }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/sports-and-games/_MG_9582.jpg" className="sports-image-disabled"></img>
          </div>
        </div>
      </div> */}



      {/* MOBILE CAROUSEL BEGIN */}

      {/* SCREEN 1*/}

      <div className="mobile-picture-gallery-container">

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/_MG_9582.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(7) }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/sports-and-games/_MG_9582.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/sports-and-games/101_2815.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/sports-and-games/101_2945.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/sports-and-games/101_3599.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 2*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/101_2815.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/sports-and-games/_MG_9582.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/sports-and-games/101_2815.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/sports-and-games/101_2945.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/sports-and-games/101_3599.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 3*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/101_2945.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/sports-and-games/_MG_9582.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/sports-and-games/101_2815.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/sports-and-games/101_2945.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/sports-and-games/101_3599.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 4*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/101_3599.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/sports-and-games/_MG_9582.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/sports-and-games/101_2815.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/sports-and-games/101_2945.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/sports-and-games/101_3599.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>

        {/* SCREEN 5*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/101_3729.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/sports-and-games/101_3729.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/sports-and-games/102_5618.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/sports-and-games/DSCF0996.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/sports-and-games/DSCF1522.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 6*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/102_5618.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/sports-and-games/101_3729.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/sports-and-games/102_5618.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/sports-and-games/DSCF0996.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/sports-and-games/DSCF1522.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 7*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/DSCF0996.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/sports-and-games/101_3729.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/sports-and-games/102_5618.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/sports-and-games/DSCF0996.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/sports-and-games/DSCF1522.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>
        {/* SCREEN 8*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/sports-and-games/DSCF1522.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/sports-and-games/101_3729.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/sports-and-games/102_5618.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/sports-and-games/DSCF0996.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/sports-and-games/DSCF1522.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}

export default SportsAndGames;
