import React, { useState, useEffect } from 'react';
import "./fks-force.css";

function FksForceP() {

    return (
        <div className="fks-force">
            <div className="cover">
                <img src="/playgroup-images/ff-philomissionvission/FF Banner-min.png" className="cover-image fks-force-cover pg-fks-force-cover" />
                <span className="a-nav">
                    <span className="a-nav-item">
                        <span className="a-nav-heading">About Us</span>
                    </span>
                    <span className="a-nav-item">
                        <a href="/playgroup-jubileehills/our-story" className="a-nav-link">Our Story</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/playgroup-jubileehills/vision-and-mission" className="a-nav-link ">FKS Perspective - Philosophy, Vision &amp; Mission</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/playgroup-jubileehills/fks-force" className="a-nav-link a-nav-item-selected">FKS Force (Team)</a>
                    </span>
                </span>
            </div>

            <div className="section" >

                <div className="section-content pg-fks-force-full-width">
                    <span className="content-text">
                        <span className="title">FKS FORCE</span>
                        It began as a flicker of hope in the heart of Shailaja, and today is a world in itself. The FKS force is a holy trinity of knowledgeable, dedicated and jovial teachers and support staff and enthusiastic parent volunteers – who are all fuelled by our core values that hold us together, which provides not only a platform for us to grow upon but also serve as a guide rail to keep us focused on our goals. The FKS core values develop strong bonds and provide a pedestal for personal growth.
          </span>
                </div>

                {/* <div className="section-image-container fks-force-image-1-container">
                    <img src="/playgroup-images/ff-philomissionvission/Banner FKS Force-min.jpg" className="section-image"></img>
                </div> */}
            </div>



            <div className="section">
                {/* <div className="section-image-container">
                    <img src="/playgroup-images/ff-philomissionvission/FF Pic-min.jpg" className="section-image"></img>
                </div> */}
                <div className="section-image-container fks-force-image-1-container">
                    <img src="/playgroup-images/ff-philomissionvission/Banner FKS Force-min.png" className="section-image"></img>
                </div>

                <div className="section-content">
                    <span className="content-text">
                        <span className="title">TEACHERS</span>
                        Life is all about constant learning – and our teachers are students themselves. As receptors of knowledge in the process of facilitating the children, the teachers are evolving, exploring and enriching the FKS ecosystem. The result is a relationship way beyond that of the teacher and the taught. They are making a connect every day, in every way!
          </span>

                </div>

            </div>



            <div className="section">

                <div className="section-content">
                    <span className="content-text">
                        <span className="title">SUPPORT STAFF</span>
                        An integral part of this ever-growing family, “Didis” and “Bhaiyas” are the invisible forces who keep this colossal ship called FKS sailing smoothly through rain and shine. Their enthusiasm fuels everything from the extensive transport network that ferries our children and staff to and from the school, the kitchens that nourish and feed everyone who walks in through the gates and the total upkeep of the FKS World. <br /><br />

                        Like the children, the teachers and support staff wear uniforms. “We don’t want to force something upon the children that we ourselves can’t follow – so we wear uniforms too!” says the FKS Staff. An instance of many measures of instilling a belief of unity and life led by example.


          </span>
                </div>
                <div className="section-image-container fks-force-image-3-container">
                    <img src="/playgroup-images/ff-philomissionvission/Didi 2-min.png" className="section-image ff-img-3"></img>
                </div>
            </div>



            <div className="section">
                <div className="section-image-container">
                    <img src="/playgroup-images/ff-philomissionvission/Parent-min.jpg" className="section-image"></img>
                </div>
                <div className="section-content mobile-margin">
                    <span className="content-text">
                        <span className="title">PARENTS</span>
                        Reinforcing our belief in what we are doing for the children is right, the parent volunteers are our phenomenal motivating force. They inspire us to outshine ourselves every day. The philosophy is to ensure that the holy trinity of school, child and the parent is upheld at all times. The focus for both school and the parent being their child. <br /><br />

                        Over the years, this belief has been reflected in the parents’ unflinching faith in the FKS team. We have parents  walk with us  in their child’s learning journey as volunteers, mentors and friends. This has helped us build a coherent world of common understanding, philosophies and foundation for ‘our’ children.
                    </span>
                </div>
            </div>

        </div>
    );
}

export default FksForceP;
