import React, { useState, useEffect } from 'react';
import "./our-story.css";

/*MOBILE GALLERY LOGIC */



function OurStoryPlaygroupNarsingi() {

  const [selectedImg, setSelectedImg] = useState(0)

   useEffect(() => {
     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
       document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
     }
     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
   }, [selectedImg])

   function mobCarouselNext() {
     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
       if (selectedImg == 7) {
         break;
       }
       document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
       document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
       setSelectedImg(selectedImg + 1)

     }
  }

   function mobCarouselPrev() {
     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
       if (selectedImg == 0) {
         break;
       }
       document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
       document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
       setSelectedImg(selectedImg - 1)
     }
   }


  return (
    <div className="our-story">
      <div className="cover">
        <img src="/images/our-story/Our Story Banner.jpg" className="cover-image our-story-cover-image" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">About Us</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/our-story" className="a-nav-link pg-mob-nav-header a-nav-item-selected">Our Story</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/vision-and-mission" className="a-nav-link ">FKS Perspective - Philosophy, Vision &amp; Mission</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/fks-force" className="a-nav-link pg-mob-nav-header ">FKS Force (Team)</a>
          </span>
        </span>
      </div>

      <div className="section our-story-section">
        <div className="section-content our-story-section-content">
          <span className="section-content-text">
            <span className="title our-story-title">OUR STORY</span>
            When asked what motivated her to start FKS, <b>Ms. Shailaja Rao, The Prime Mover of The Future Kid’s School (FKS)</b>, responded that she had the “benefit of the worst teachers.” <br/><br/>

            She was the victim of teacher discouragement and rebuke while in school. All she wanted to do in her lifetime was to create a place of learning which the child would cherish, look forward to going to every day. She wanted to build an institution where every child was understood for who she or he was. This, Shailaja wanted to achieve by moving away from conventional methods of teaching.<br/><br/>

            In 1997, a dream was born in Hyderabad. A 4,000 sq. ft. of rented space housed 20 eager minds, one teacher and 5 support staff. The dream was christened The Future Kid’s School. This dream grew steadily and by 2005, FKS had over 650 children, 65 teachers and 55 support staff to make it one big family.<br/><br/>
            
            Today, in 2024, it stands tall and proud. Three campuses host over 3000+ students, 250+ teachers, over 380 support staff, a kitchen that feeds around 3500 members every day and plies 98 school buses.
          </span>
        </div>
      </div>

      <div className="curvy-container">
        <div className="curvy-image-1-container">
          <img src="/images/our-story/OS 4.jpg" className="curvy-image-1 our-story-curvy-image-1" />
        </div>
        <div className="curvy-image-2-container">
          <img src="/images/our-story/OS 3.jpg" className="curvy-image-2 our-story-curvy-image-2" />
        </div>
        <div className="curvy-image-3-container">
          <img src="/images/our-story/OS 7.jpg" className="curvy-image-3 our-story-curvy-image-3" />
        </div>
      </div>

      {/* MOBILE CAROUSEL BEGIN */}

      {/* SCREEN 1*/}

      <div className="mobile-picture-gallery-container">

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/our-story/Our Story Banner 2.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(7) }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/our-story/Our Story Banner 2.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/our-story/OS 7.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/our-story/OS 3.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/our-story/OS 4.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 2*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/our-story/OS 7.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/our-story/Our Story Banner 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/our-story/OS 7.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/our-story/OS 3.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/our-story/OS 4.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 3*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/our-story/OS 3.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/our-story/Our Story Banner 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/our-story/OS 7.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/our-story/OS 3.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/our-story/OS 4.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 4*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/our-story/OS 4.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/our-story/Our Story Banner 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/our-story/OS 7.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/our-story/OS 3.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/our-story/OS 4.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>

        {/* SCREEN 5*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/our-story/OS 1.png.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/our-story/OS 1.png.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/our-story/OS 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/our-story/OS 5.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/playgroup-images/beyond-learning/9-min.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 6*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/our-story/OS 2.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/our-story/OS 1.png.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/our-story/OS 2.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/our-story/OS 5.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/playgroup-images/beyond-learning/9-min.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 7*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/our-story/OS 5.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/our-story/OS 1.png.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/our-story/OS 2.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/our-story/OS 5.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/playgroup-images/beyond-learning/9-min.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>
        {/* SCREEN 8*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/playgroup-images/beyond-learning/9-min.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/our-story/OS 1.png.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/our-story/OS 2.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/our-story/OS 5.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/playgroup-images/beyond-learning/9-min.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default OurStoryPlaygroupNarsingi;
