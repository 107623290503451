import React,{ useState, useEffect } from 'react';
import "./campus.css";

function CampusPlaygroupNarsingi() {

    return (
        <div className="campus">
            <div className="cover">
                <img src="/playgroup-images/campus/Banner-2-min.JPG" className="cover-image playgroup-campus-cover-image" />
            </div>

            <div className="section dual-image-section pg-campus-section">
                <div className="section-image-container fks-force-image-dual-container pg-dual-img">
                    <img src="/playgroup-images/campus/Campus 1-min.jpg" className="section-image fks-force-image-3"></img>
                </div>
                <div className="section-content">
                    <span className="content-text">
                        <span className="title">CAMPUS</span>

                        All spaces in the campus are learning spaces. The children move to different areas, different classes, outdoors and indoors and the spaces are set up to hold children’s attention for short durations so that they move and learn around the school. Whether it is the steps to the tree house, all spaces get used by all children all through the days.<br/><br/>

Our space is a continuous work-in-progress, with the children helping plan, plant and care for the landscape that consists of a variety of ever-evolving flora and fauna – a vital step in creating environmental awareness among our children.<br/><br/>

With the outside world, we are connected through a wide network of school buses and transportation vans and inside, we are connected to one another through a seamless flow of spaces. We boast of a staff-room free school, classrooms with abundant natural light and ventilation and open spaces for children to use freely.<br/><br/>

Choosing constructive over the destructive, including over excluding and being environment-friendly, FKS has kept in mind its inhabitants – the children – at the centre of its philosophy always – and designed its spaces keeping in mind their age-groups, the climate and the maximum freedom that can be made available to them for their unhindered growth. 

          </span>
                </div>
                <div className="section-image-container  play-group-campus-dual">
                    <img src="/playgroup-images/campus/Lawns-min.png" className="section-image fks-force-image-3"></img>
                </div>
            </div>

            {/* <div className="section">
                <div className="section-image-container">
                    <img src="/images/campus/_RA_0185_FKS.JPG" className="section-image campus-image-2"></img>
                </div>
                <div className="section-content text-align-center">
                    <span className="content-text">
                        Allowing topography to lead architectural design and implementation, are 3 distinct structures for primary, middle school and high school integrating seamlessly through the landscape. These dainty buildings create a cohesive learning space between them that accommodates children and offers each phase of learning, exactly what it needs in terms of infrastructure. <br /><br />
                        Our space is a continuous work-in-progress, where our children help us plan, plant and care look after the landscape that consists of a variety of ever-evolving flora and fauna – a vital step in creating environmental awareness among them.

          </span>
                </div>
            </div>

            <div className="section">
                <div className="section-content text-align-center">
                    <span className="content-text">
                        With the outside world, we are connected through a wide network of school buses and transportation vans and inside, we are connected to one another through a seamless flow of spaces. We boast of a staff-room free school, classrooms with abundant natural light and ventilation and open spaces for children to use freely.<br /><br />
                        Choosing constructive over the destructive, including over excluding and being environment-friendly, FKS has kept in mind its inhabitants – the children – at the center of its philosophy always – and designed its spaces keeping in mind their age-groups, the climate and the maximum freedom that can be made available to them for their unhindered growth.

          </span>
                </div>
                <div className="section-image-container mobile-margin">
                    <img src="/images/campus/_MG_0093.jpg" className="section-image campus-image-3"></img>
                </div>
            </div> */}

            {/* <div className="curvy-container">
                <div className="curvy-image-1-container">
                    <img src="/images/campus/_RA_0212_FKS.JPG" className="curvy-image-1 campus-curvy-image-1" />
                </div>
                <div className="curvy-image-2-container">
                    <img src="/images/campus-new/image-4.JPG" className="curvy-image-2 campus-curvy-image-2" />
                </div>
                <div className="curvy-image-3-container">
                    <img src="/images/campus/20140823_122815.jpg" className="curvy-image-3 campus-curvy-image-3" />
                </div>
            </div> */}

        </div>
    );
}

export default CampusPlaygroupNarsingi;
