import { useState, useEffect } from 'react';
import "./field-trips.css";

function FieldTrips() {

    /*MOBILE GALLERY LOGIC */

    const [selectedImg, setSelectedImg] = useState(0)

    // useEffect(() => {
    //     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
    //         document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
    //     }
    //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
    // }, [selectedImg])

    // function mobCarouselNext() {
    //     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
    //         if (selectedImg == 4) {
    //             break;
    //         }
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
    //         setSelectedImg(selectedImg + 1)

    //     }
    // }

    // function mobCarouselPrev() {
    //     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
    //         if (selectedImg == 0) {
    //             break;
    //         }
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
    //         setSelectedImg(selectedImg - 1)
    //     }
    // }

    return (
        <div className="field-trips">
            <div className="cover">
                <img src="/images/field-trips/Trips 1.jpg" className="cover-image field-trips-cover" />
                <span className="a-nav">
                    <span className="a-nav-item">
                        <span className="a-nav-heading">Beyond Learning</span>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/leadership" className="a-nav-link">Leadership at FKS</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/sports-and-games" className="a-nav-link ">Sports, Games &amp; Physical Activities</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/events-and-festivals" className="a-nav-link ">Events &amp; Festivals</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/theatre-and-drama" className="a-nav-link">Performing Art</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/field-trips" className="a-nav-link a-nav-item-selected">Field Trips</a>
                    </span>
                </span>
            </div>

            <div className="section ">
                <div className="section-content text-align-center">
                    <span className="content-text">
                        <span className="title">FIELD &amp; OUT OF STATION TRIPS</span>
                        Stepping beyond the boundaries of the school with a purpose in mind works wonders on young children. While improving interpersonal relationships with peers, it also instils awareness, endurance, patience and grit, which can never be taught in the confines of a classroom. <br /><br />
                        This world is only as creepy as we make it – so at FKS our motto is to dare to explore our world and its wonders – and in the process – find ourselves!
          </span>
                </div>
                <div className="section-image-container field-trips-image-1-container">
                    <img src="/images/field-trips/IMG20180821171036.jpg" className="section-image"></img>
                </div>
            </div>

            <div className="section">
                <div className="section-image-container">
                    <img src="/images/field-trips/IMG-20190212-WA0038.jpg" className="section-image field-trips-image-2"></img>
                </div>
                <div className="section-content mobile-margin text-align-center">
                    <span className="content-text">
                        With a beautiful, diverse and huge country like ours, with its mountains and beaches and snow and sand to explore – FKS plans its trips within India, to unveil the beauty of our motherland with our children. Rest of the world? Maybe someday, when we are done exploring our home!
          </span>
                </div>
            </div>

            <div className="curvy-container">
                <div className="curvy-image-1-container">
                    <img src="/images/field-trips/IMG_5346.jpg" className="curvy-image-1 field-trips-curvy-image-1" />
                </div>
                <div className="curvy-image-2-container">
                    <img src="/images/field-trips/IMG20180926065446.jpg" className="curvy-image-2 field-trips-curvy-image-2" />
                </div>
                <div className="curvy-image-3-container">
                    <img src="/images/field-trips/IMG20180925104156.jpg" className="curvy-image-3 field-trips-curvy-image-3" />
                </div>
            </div>

            {/* MOBILE CAROUSEL BEGIN */}

            {/* SCREEN 1*/}

            {/* <div className="mobile-picture-gallery-container">

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/field-trips/IMG_5346.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(3) }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/field-trips/IMG_5346.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/field-trips/IMG20180926065446.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/field-trips/IMG20180925104156.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/field-trips/IMG_3259.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 2*

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/field-trips/IMG20180926065446.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/field-trips/IMG_5346.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/field-trips/IMG20180926065446.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/field-trips/IMG20180925104156.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/field-trips/IMG_3259.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 3*

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/field-trips/IMG20180925104156.jpg" className="mobile-picture-gallery-focus-image flield-gallery-mobile-align" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/field-trips/IMG_5346.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/field-trips/IMG20180926065446.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/field-trips/IMG20180925104156.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/field-trips/IMG_3259.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 4*

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/field-trips/IMG_3259.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/field-trips/IMG_5346.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/field-trips/IMG20180926065446.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/field-trips/IMG20180925104156.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/field-trips/IMG_3259.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                    </div>
                </div>


            </div> */}

        </div>
    );
}

export default FieldTrips;
