import "./board-and-curriculum.css";

function BoardAndCurriculum() {
  return (
    <div className="board-and-curriculum">
      <div className="cover">
        <img src="/images/board-and-curriculum/7.jpg" className="cover-image board-and-curriculum-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Learning at FKS</span>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/board-and-curriculum" className="a-nav-link a-nav-item-selected">Board &amp; Curriculum</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/pedagogy" className="a-nav-link ">Pedagogy</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/extra-activities" className="a-nav-link ">Extra &amp; Co-curricular Activities</a>
          </span>
        </span>
      </div>

      <div className="section dual-image-section">
        <div className="section-image-container fks-force-image-dual-container board-and-curriculum-image-1-container">
          <img src="/images/board-and-curriculum/Board Banner.jpg" className="section-image board-and-curiculum-image-1"></img>
        </div>
        <div className="section-content text-align-center">
          <span className="title">BOARD &amp; CURRICULUM</span>

          "If an egg is broken by an outside force, life ends. If broken by force within, life begins. Great things always begin from within." <br /><br />
          Our lessons are based on the CICSE board recommendations, but not limited to them! FKS envisions more than mere textbook learning. It creates a stimulating and dynamic learning atmosphere. FKS has uniquely designed programs that hone various facets of children that allow them to blossom at their own pace.
          <br /><br />
          FKS has adopted the Indian School Certificate (ISC) syllabus for grades 11 and 12. On careful assessment, FKS believes that ISC has an in-depth syllabus. This coupled with innovative methods are a great advantage for students.

        </div>
        <div className="section-image-container board-and-curriculum-image-2-container">
          <img src="/images/board-and-curriculum/1.jpg" className="section-image "></img>
        </div>
      </div>

      {/* <div className="section">
        <div className="section-image-container">
          <img src="/images/board-and-curriculum/Board Banner.jpg" className="section-image"></img>
        </div>
        <div className="section-content">
          FKS has adopted the Indian School Certificate (ISC) syllabus for grades 11 and 12. On careful assessment, FKS believes that ISC has an in-depth syllabus. This coupled with innovative methods are a great advantage for students.

        </div>
      </div> */}



      <div className="section">
        <div className="section-content text-align-center">
          <span className="title academic-accolades-title">ACADEMIC ACCOLADES</span>
          FKS students have aced exams across different streams and came within the first three ranks in the city of Hyderabad, Telangana. Apart from this Students have secured commendable ranks in various regional and national Competitive Exams and Olympiads.<br /><br />
          FKS Alumni are spread across the best of universities across the world in the fields of Music, Psychology, Medicine, Computer Science, Media, Engineering, Law, Bio-medical, Economics and Finance, Film making, Architecture etc. Some are entrepreneurs in Events, Design, Movies, Film Making, Playback Singing, Data Analytics, E-Commerce, Science &amp; Technology, Social Entrepreneurs and many more in the making.
        </div>
        <div className="section-image-container board-and-curriculum-image-3-container">
          <img src="/images/board-and-curriculum/DSC08939.jpg" className="section-image"></img>
        </div>
      </div>



      {/* <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Life Skills</span>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/life-skills.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Life Skills</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/critical-thinking.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Critical Thinking</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/creative-thinking-and-writing.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Creative Thinking &amp; Writing</span>
              </div>
            </div>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/reading-programme.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Reading Programme</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/effective-communication.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Effective Communication</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/funtoot.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Funtoot Online Math Programme</span>
              </div>
            </div>
          </span>
        </div>
      </div> */}



      {/* <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Special Clubs</span>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/logical-and-analytical-skills.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Logical &amp; Analytical Skills</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/spell-fun.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Spell Fun</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/memory-enhancement.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Memory Enhancement</span>
              </div>
            </div>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/general-knowledge.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">General Knowledge</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/arts.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Arts</span>
              </div>
            </div>
          </span>
        </div>
      </div> */}

    </div>
  );
}

export default BoardAndCurriculum;
