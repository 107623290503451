import "./landing.css";
import { useState, useEffect } from 'react';
import Footer from "../../componenets/footer/footer";

function LandingPage() {

  //main carousel 
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('carousel-image-container').length; i++) {
      document.getElementsByClassName('carousel-image-container')[i].style.display = "none";
    }
    document.getElementsByClassName('carousel-image-container')[current].style.display = "block";
  }, [current])

  function mainCarouselNext() {
    if (current < 3) {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[current + 1].style.display = "block";
      setCurrent(current + 1)
      console.log(current);
    }
    else {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[0].style.display = "block";
      setCurrent(0);

    }
  }

  function mainCarouselPrev() {
    if (current > 0) {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[current - 1].style.display = "block";
      setCurrent(current - 1)
      console.log(current);
    }
    else {
      document.getElementsByClassName('carousel-image-container')[current].style.display = "none";
      document.getElementsByClassName('carousel-image-container')[3].style.display = "block";
      setCurrent(3);

    }
  }

  // useEffect(() => {
  //   if (current < 4) {
  //     setTimeout(() => {
  //       setCurrent(current + 1);
  //       for (var i = 0; i < document.getElementsByClassName('carousel-image-container').length; i++) {
  //         document.getElementsByClassName('carousel-image-container')[i].style.display = "none"
  //       }
  //       document.getElementsByClassName('carousel-image-container')[current].style.display = "block"
  //     }, 3000)
  //   }
  //   else {
  //     setTimeout(() => {
  //       setCurrent(0)
  //       for (var i = 0; i < document.getElementsByClassName('carousel-image-container').length; i++) {
  //         document.getElementsByClassName('carousel-image-container')[i].style.display = "none"
  //       }
  //       document.getElementsByClassName('carousel-image-container')[0].style.display = "block"
  //     }, 3000)
  //   }
  // }, [current])



  const [selectedEvent, setSelectedEvent] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('events-carousel-item').length; i++) {
      document.getElementsByClassName('events-carousel-item')[i].style.display = "none";
    }
    document.getElementsByClassName('events-carousel-item')[selectedEvent].style.display = "flex";
  }, [selectedEvent])

  function eventsCarouselNext() {
    for (var i = 0; i < document.getElementsByClassName('events-carousel-item').length; i++) {
      if (selectedEvent == 3) {
        break;
      }
      document.getElementsByClassName('events-carousel-item')[selectedEvent].style.display = "none";
      document.getElementsByClassName('events-carousel-item')[selectedEvent + 1].style.display = "flex";
      setSelectedEvent(selectedEvent + 1)

    }
  }

  function eventsCarouselPrev() {
    for (var i = 0; i < document.getElementsByClassName('events-carousel-item').length; i++) {
      if (selectedEvent == 0) {
        break;
      }
      document.getElementsByClassName('events-carousel-item')[selectedEvent].style.display = "none";
      document.getElementsByClassName('events-carousel-item')[selectedEvent - 1].style.display = "flex";
      setSelectedEvent(selectedEvent - 1)

    }
  }


  const [selected, setSelected] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('highlights-carousel-item').length; i++) {
      document.getElementsByClassName('highlights-carousel-item')[i].style.display = "none";
    }
    document.getElementsByClassName('highlights-carousel-item')[selected].style.display = "flex";
  }, [selected])

  function carouselNext() {
    for (var i = 0; i < document.getElementsByClassName('highlights-carousel-item').length; i++) {
      if (selected == 4) {
        break;
      }
      document.getElementsByClassName('highlights-carousel-item')[selected].style.display = "none";
      document.getElementsByClassName('highlights-carousel-item')[selected + 1].style.display = "flex";
      setSelected(selected + 1)

    }
  }

  function carouselPrev() {
    for (var i = 0; i < document.getElementsByClassName('highlights-carousel-item').length; i++) {
      if (selected == 0) {
        break;
      }
      document.getElementsByClassName('highlights-carousel-item')[selected].style.display = "none";
      document.getElementsByClassName('highlights-carousel-item')[selected - 1].style.display = "flex";
      setSelected(selected - 1)

    }
  }





  return (
    <div className="landing">

      <div className="carousel">
        <div className="carousel-image-container">
          <img src="/images/landing-page/Home Banner 1.png" className="carousel-image carousel-image-1" />
          <span className="carousel-text carousel-text-1">
            FKS gives the child an education as a “child would want it,” where they are ‘schooled for a journey called life!"
                    </span>
          <div className="main-carousel-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} />
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>
        </div>
        <div className="carousel-image-container">
          <img src="/images/landing-page/Home Banner 2.png" className="carousel-image" />
          <span className="carousel-text">We believe in organic growth from within because such a change carries the power to transform self and others.
          </span>
          <div className="main-carousel-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} />
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>

        </div>
        <div className="carousel-image-container">
          <img src="/images/landing-page/Slide 3.jpg" className="carousel-image carousel-image-3" />
          <span className="carousel-text">We fuel in them the willingness to learn and zest to take on challenges. Thus, developing in them the quest for knowledge and saying yes to opportunities.
          </span>
          <div className="main-carousel-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} />
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>

        </div>
        <div className="carousel-image-container">
          <img src="/images/landing-page/Slide 4.jpg" className="carousel-image" />
          <span className="carousel-text">We work towards making the future generations responsible, work with equanimity and integrity; Creating a strong foundation where they balance head and heart.
          </span>
          <div className="main-carousel-button-container">
            <div className="button-box">
              <img src="/icons8-left-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselPrev() }} />
              <div className="dots-box">
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(0) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(1) }} />
                <img src="/icons8-select1-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(2) }} />
                <img src="/icons8-select-48.png" className="carousel-dot-icon" onClick={e => { e.preventDefault(); setCurrent(3) }} />
              </div>
              <img src="/icons8-right-64.png" className="events-carousel-icon main-carousel-icon" onClick={e => { e.preventDefault(); mainCarouselNext() }} />
            </div>
          </div>

        </div>
      </div>

      <div className="section">
        <div className="section-content about-section-lnding-page">
          <span className="title">ABOUT FKS</span>
          FKS was a dream born on 8 February 1997 with an objective to give the child an education as a “child would want it,” an education which  fostered emotional balance, and an education that instilled integrity.
          <br /><br />
          <div className="about-section-redirect-container">
            <a href="/main-campus/our-story" className="landing-3e-link">Know More</a>
          </div>

        </div>
        <div className="section-image-container landing-page-image-1-container">
          <img src="/images/landing-page/Check.png" className="section-image landing-image-1"></img>
        </div>
      </div>

      <div className="section">
        <div className="section-content landing-section-content">
          <span className="title">Foundation of FKS Pedagogy is the 3Es</span>

          <div className="landing-3e-section">
            <span className="landing-3e-section-title">Environment</span>
            <br />
            <span className="landing-3e-section-content">FKS is a space which would bend backwards for the child. An environment where a child will find herself/himself to be their own best version. It is an environment where all spaces are for learning and growing.</span>
            <div className="landing-3e-section-image-container">
              <img src="/images/landing-page/Enivronment.jpg" className="landing-3e-section-image landing-3e-environment-image" />
            </div>
            <div className="landing-3e-link-container">
              <a href="/main-campus/pedagogy" className="landing-3e-link">Know More</a>
            </div>
          </div>


          <div className="landing-3e-section">
            <span className="landing-3e-section-title">Exploration</span>
            <br />
            <span className="landing-3e-section-content">The environment lends itself to students to explore themselves. Hands-on learning helps them to explore who they are, who they are in the process of becoming.</span>
            <div className="landing-3e-section-image-container">
              <img src="/images/landing-page/Explore 2.png.jpg" className="landing-3e-section-image landing-3-e-exploration-image" />
            </div>
            <div className="landing-3e-link-container">
              <a href="/main-campus/pedagogy" className="landing-3e-link">Know More</a>
            </div>
          </div>

          <div className="landing-3e-section">
            <span className="landing-3e-section-title">Experience</span>
            <br />
            <span className="landing-3e-section-content">Students in school are constantly learning something new in life, every day. They are surrounded by an experience of oneness, equality, and respect.</span>
            <div className="landing-3e-section-image-container">
              <img src="/images/landing-page/Experience.jpg" className="landing-3e-section-image " />
            </div>
            <div className="landing-3e-link-container">
              <a href="/main-campus/pedagogy" className="landing-3e-link">Know More</a>
            </div>
          </div>


        </div>
      </div>


      <div className="section">
        <div className="section-content fks-tribune-section-content">
          <span className="title">FKS TRIBUNE</span>
          <div className="tribune-item-row">
            <a href="https://fkstribune.in/magazine/Html/Cultural.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-cultural.jpeg" className="tribune-item-image" />
              <label className="tribune-label">CULTURAL</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Literary.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-literary.jpeg" className="tribune-item-image" />
              <label className="tribune-label">LITERARY</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Human_Gemstone.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-human-gemstone.jpeg" className="tribune-item-image" />
              <label className="tribune-label">HUMAN GEMSTONES</label>
            </a>
          </div>
          <div className="tribune-item-row">
            <a href="https://fkstribune.in/magazine/Html/FKS_News.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-news.jpeg" className="fks-tribune-item-img" className="tribune-item-image" />
              <label className="tribune-label">FKS NEWS</label>
            </a>
            <a href="https://fkstribune.in/magazine/Games/Games.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-fun-games.png" className="tribune-item-image" />
              <label className="tribune-label">FUN GAMES</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Fun_Facts.html" target="_blank" className="tribune-item">
              <img src="/images/landing-page/fks-tribune-fun-facts.jpg" className="tribune-item-image" />
              <label className="tribune-label">FUN FACTS</label>
            </a>
          </div>

          <div className="tribune-item-row-mob">
            <a href="https://fkstribune.in/magazine/Html/Cultural.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-cultural.jpeg" className="tribune-item-image" />
              <label className="tribune-label">CULTURAL</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Literary.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-literary.jpeg" className="tribune-item-image" />
              <label className="tribune-label">LITERARY</label>
            </a>
          </div>

          <div className="tribune-item-row-mob">
            <a href="https://fkstribune.in/magazine/Html/Human_Gemstone.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-human-gemstone.jpeg" className="tribune-item-image" />
              <label className="tribune-label">HUMAN GEMSTONES</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/FKS_News.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-news.jpeg" className="fks-tribune-item-img" className="tribune-item-image" />
              <label className="tribune-label">FKS NEWS</label>
            </a>
          </div>

          <div className="tribune-item-row-mob">
            <a href="https://fkstribune.in/magazine/Games/Games.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-fun-games.png" className="tribune-item-image" />
              <label className="tribune-label">FUN GAMES</label>
            </a>
            <a href="https://fkstribune.in/magazine/Html/Fun_Facts.html" target="_blank" className="tribune-item-mob">
              <img src="/images/landing-page/fks-tribune-fun-facts.jpg" className="tribune-item-image" />
              <label className="tribune-label">FUN FACTS</label>
            </a>
          </div>
        </div>
      </div>


      <div className="section">
        <div className="section-content landing-section-content news-and-highlights-container">
          <span className="title">EVENTS 2020-2021</span>
          <div className="events-carousel-container">

            {/* Carousel item - 1  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/4Vse_xpdj0w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelectedEvent(3) }} />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); eventsCarouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" src="https://www.youtube.com/embed/0NNNSyuuzjA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/xS7djhBbDs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>


            {/* Carousel item - 2  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/0NNNSyuuzjA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); eventsCarouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); eventsCarouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/xS7djhBbDs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
            </div>

            {/* Carousel item - 3  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/xS7djhBbDs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); eventsCarouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); eventsCarouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/J6DVgz5lcBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/4Vse_xpdj0w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
            </div>

            {/* Carousel item - 4  */}

            <div className="events-carousel-item">
              <div className="events-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/J6DVgz5lcBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              </div>
              <div className="events-carousel-item-center">
                <div className="events-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); eventsCarouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelectedEvent(0) }} />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/4Vse_xpdj0w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="events-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/0NNNSyuuzjA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          {/* <div className="yt-video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/czkeZ9iUIiI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="yt-video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/jcFnbhXvCWs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="yt-video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div> */}
        </div >
      </div >

      < div className="section" >
        <div className="section-content landing-section-content news-and-highlights-container">
          <span className="title">FROM THE FOUNDERS DESK</span>
          <div className="highlights-carousel-container">

            {/* Carousel item - 1  */}

            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/r6HoUkqsibY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelected(4) }} />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/0utH05N7j4w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>


            {/* Carousel item - 2  */}

            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/0utH05N7j4w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
              </div>
            </div>

            {/* Carousel item - 3  */}


            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/DLKSWYdKTRI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
              </div>
            </div>

            {/* Carousel item - 4 */}


            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/8QboKvQ_V90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" onClick={e => { e.preventDefault(); carouselNext() }} className="events-carousel-icon" />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/DLKSWYdKTRI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/r6HoUkqsibY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselNext() }}></div>
              </div>
            </div>


            {/* Carousel item - 5 */}

            <div className="highlights-carousel-item">
              <div className="highlights-carousel-item-left">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/DLKSWYdKTRI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="overlay-container" onClick={e => { e.preventDefault(); carouselPrev() }}></div>
              </div>
              <div className="highlights-carousel-item-center">
                <div className="highlights-central-buttons-container">
                  <img src="/icons8-left-64.png" onClick={e => { e.preventDefault(); carouselPrev() }} className="events-carousel-icon" />
                  <img src="/icons8-right-64.png" className="events-carousel-icon" onClick={e => { e.preventDefault(); setSelected(0) }} />
                </div>
                <iframe className="central-iframe-item yt-iframe-main" width="400" height="250" src="https://www.youtube.com/embed/r6HoUkqsibY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="highlights-carousel-item-right">
                <iframe className="yt-iframe-sub" src="https://www.youtube.com/embed/pfREBI69jtk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>


          </div>

        </div>
      </div >



    </div >
  );
}

export default LandingPage;
