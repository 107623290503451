import { useState, useEffect } from 'react';
import "./theatre-and-drama.css";

function TheatreAndDrama() {


  /*MOBILE GALLERY LOGIC */

  // const [selectedImg, setSelectedImg] = useState(0)

  // useEffect(() => {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
  //   }
  //   document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
  // }, [selectedImg])

  // function mobCarouselNext() {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     if (selectedImg == 7) {
  //       break;
  //     }
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
  //     setSelectedImg(selectedImg + 1)

  //   }
  // }

  // function mobCarouselPrev() {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     if (selectedImg == 0) {
  //       break;
  //     }
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
  //     setSelectedImg(selectedImg - 1)
  //   }
  // }

  return (
    <div className="theatre-and-drama">
      <div className="cover">
        <img src="/images/theatre-and-drama/sk_1115future kids.jpg" className="cover-image theatre-and-drama-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Beyond Learning</span>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/leadership" className="a-nav-link">Leadership at FKS</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/sports-and-games" className="a-nav-link ">Sports, Games &amp; Physical Activities</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/events-and-festivals" className="a-nav-link ">Events &amp; Festivals</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/theatre-and-drama" className="a-nav-link a-nav-item-selected">Performing Art</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/field-trips" className="a-nav-link">Field Trips</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content">
          <span className="content-text">
            <span className="title">PERFORMING ART</span>
            Among its many advantages, any art form instils poise, imagination, compassion and an outlet for the budding creative juices of our children. Playing music instruments or performing any of the dance forms brings stability and cultivates focus of the mind and body – boosting positivity and make the kids more aware of themselves. <br /><br />Drama, on the other hand enhances spoken and expressive communication skills, improves voice projections, semantic eloquence and persuasive speech which benefit children in every walk of life. However, our favorite part of art forms at FKS is that it brings a lot of fun in learning and reduces stress levels – making our happy place, happier!
          </span>
        </div>
        <div className="section-image-container">
          <img src="/images/theatre-and-drama/SK0179_FutureKids Day 2.jpg" className="section-image theatre-and-drama-image-1"></img>
        </div>
      </div>


      <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Performing Art at FKS</span>

            <div className="icons-row">
              <div className="icon-container td-icon-container">
                <img src="/icons/mridangam.png" className="icon-image" />
                <span className="icon-description">Mridangam</span>
              </div>
              <div className="icon-container td-icon-container">
                <img src="/icons/violin.png" className="icon-image" />
                <span className="icon-description">Violin</span>
              </div>
              <div className="icon-container td-icon-container">
                <img src="/icons/guitar.png" className="icon-image" />
                <span className="icon-description">Guitar</span>
              </div>
              <div className="icon-container td-icon-container">
                <img src="/icons/folk-dance.png" className="icon-image" />
                <span className="icon-description">Folk Dance</span>
              </div>
            </div>

            <div className="icons-row">
              <div className="icon-container">
                <img src="/icons/classical-dance.png" className="icon-image" />
                <span className="icon-description">Classical Dance</span>
              </div>
              <div className="icon-container ">
                <img src="/icons/drums.png" className="icon-image" />
                <span className="icon-description">Drums</span>
              </div>
              <div className="icon-container ">
                <img src="/icons/theatre.png" className="icon-image" />
                <span className="icon-description">Theatre</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container td-icon-container">
                <img src="/icons/mridangam.png" className="icon-image" />
                <span className="icon-description">Mridangam</span>
              </div>
              <div className="icon-container td-icon-container">
                <img src="/icons/violin.png" className="icon-image" />
                <span className="icon-description">Violin</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container td-icon-container">
                <img src="/icons/guitar.png" className="icon-image" />
                <span className="icon-description">Guitar</span>
              </div>
              <div className="icon-container td-icon-container">
                <img src="/icons/folk-dance.png" className="icon-image" />
                <span className="icon-description">Folk Dance</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container">
                <img src="/icons/classical-dance.png" className="icon-image" />
                <span className="icon-description">Classical Dance</span>
              </div>
              <div className="icon-container ">
                <img src="/icons/drums.png" className="icon-image" />
                <span className="icon-description">Drums</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container ">
                <img src="/icons/theatre.png" className="icon-image" />
                <span className="icon-description">Theatre</span>
              </div>
            </div>
          </span>
        </div>
      </div>

      <div className="curvy-container">
        <div className="curvy-image-1-container">
          <img src="/images/theatre-and-drama/SK0356_FutureKids Day 2.jpg" className="curvy-image-1 theatre-and-drama-curvy-image-1" />
        </div>
        <div className="curvy-image-2-container">
          <img src="/images/theatre-and-drama/sk_0452 future kids.JPG" className="curvy-image-2 theatre-and-drama-curvy-image-2" />
        </div>
        {/* <div className="curvy-image-3-container">
          <img src="/images/theatre-and-drama/sk_0020future kids.jpg" className="curvy-image-3 theatre-and-drama-curvy-image-3" />
        </div> */}
      </div>

      {/* MOBILE CAROUSEL BEGIN */}

      {/* SCREEN 1*/}

      {/* <div className="mobile-picture-gallery-container">

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/SK0356_FutureKids Day 2.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(7) }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/theatre-and-drama/SK0356_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/theatre-and-drama/sk_0452 future kids.JPG" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/theatre-and-drama/sk_0020future kids.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/theatre-and-drama/DSC_0233.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 2

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/sk_0452 future kids.JPG" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/theatre-and-drama/SK0356_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/theatre-and-drama/sk_0452 future kids.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/theatre-and-drama/sk_0020future kids.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/theatre-and-drama/DSC_0233.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 3

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/sk_0020future kids.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/theatre-and-drama/SK0356_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/theatre-and-drama/sk_0452 future kids.JPG" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/theatre-and-drama/sk_0020future kids.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/theatre-and-drama/DSC_0233.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 4

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/DSC_0233.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/theatre-and-drama/SK0356_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/theatre-and-drama/sk_0452 future kids.JPG" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/theatre-and-drama/sk_0020future kids.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/theatre-and-drama/DSC_0233.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>

        {/* SCREEN 5

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/DSC_0638.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/theatre-and-drama/DSC_0638.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/theatre-and-drama/SK0150_FutureKids Day 2 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/theatre-and-drama/sk_1014 future kids_.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/theatre-and-drama/SK0358_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 6

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/SK0150_FutureKids Day 2 2.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/theatre-and-drama/DSC_0638.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/theatre-and-drama/SK0150_FutureKids Day 2 2.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/theatre-and-drama/sk_1014 future kids_.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/theatre-and-drama/SK0358_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 7*

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/sk_1014 future kids_.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/theatre-and-drama/DSC_0638.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/theatre-and-drama/SK0150_FutureKids Day 2 2.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/theatre-and-drama/sk_1014 future kids_.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/theatre-and-drama/SK0358_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>
        {/* SCREEN 8

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/theatre-and-drama/SK0358_FutureKids Day 2.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/theatre-and-drama/DSC_0638.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/theatre-and-drama/SK0150_FutureKids Day 2 2.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/theatre-and-drama/sk_1014 future kids_.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/theatre-and-drama/SK0358_FutureKids Day 2.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default TheatreAndDrama;
