import { useState, useEffect } from 'react';
import "./fks-force.css";

function FksForce() {


    /*MOBILE GALLERY LOGIC */

    const [selectedImg, setSelectedImg] = useState(0)

    useEffect(() => {
        for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
            document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
        }
        document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
    }, [selectedImg])

    function mobCarouselNext() {
        for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
            if (selectedImg == 7) {
                break;
            }
            document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
            document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
            setSelectedImg(selectedImg + 1)

        }
    }

    function mobCarouselPrev() {
        for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
            if (selectedImg == 0) {
                break;
            }
            document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
            document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
            setSelectedImg(selectedImg - 1)
        }
    }


    return (
        <div className="fks-force">
            <div className="cover">
                <img src="/images/fks-force/banner1.jpg" className="cover-image fks-force-cover" />
                <span className="a-nav">
                    <span className="a-nav-item">
                        <span className="a-nav-heading">About Us</span>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/our-story" className="a-nav-link">Our Story</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/vision-and-mission" className="a-nav-link ">FKS Perspective - Philosophy, Vision &amp; Mission</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/fks-force" className="a-nav-link a-nav-item-selected">FKS Force</a>
                    </span>
                    <span className="a-nav-item">
                        <a href="/main-campus/nutrition" className="a-nav-link">Nutrition</a>
                    </span>
                </span>
            </div>

            <div className="section">

                <div className="section-content">
                    <span className="content-text">
                        <span className="title">FKS FORCE</span>
                        It began as a flicker of hope in the heart of Shailaja, and today is a world in itself. The FKS force is a holy trinity of knowledgeable, dedicated and jovial teachers and support staff and enthusiastic parent volunteers – who are all fueled by our core values that hold us together, which provides not only a platform for us to grow upon but also serve as a guide rail to keep us focused on our goals. The FKS core values develop strong bonds and provide a pedestal for personal growth.
          </span>
                </div>

                <div className="section-image-container fks-force-image-1-container">
                    <img src="/images/fks-force/Teachers 1.JPG" className="section-image fks-force-image-1"></img>
                </div>
            </div>



            <div className="section">
                <div className="section-image-container fks-force-image-2-container">
                    <img src="/images/fks-force/2.jpeg" className="section-image fks-force-image-2"></img>
                </div>

                <div className="section-content">
                    <span className="content-text">
                        <span className="title">TEACHERS</span>

                        Life is all about constant learning – and our teachers are students themselves. As receptors of knowledge in the process of facilitating the children, the teachers are evolving, exploring and enriching the FKS ecosystem. The result is a relationship way beyond that of the teacher and the taught. They are making a connect every day, in every way!
          </span>

                </div>

            </div>



            <div className="section dual-image-section">
                <div className="section-image-container fks-force-image-dual-container">
                    <img src="/images/fks-force/Bhaiyas.JPG" className="section-image fks-force-image-3"></img>
                </div>
                <div className="section-content">
                    <span className="content-text">
                        <span className="title">SUPPORT STAFF</span>

                        An integral part of this ever-growing family, “Didis” and “Bhaiyas” are the invisible forces who keep this colossal ship called FKS sailing smoothly through rain and shine.<br /><br />
                        Like the children, the teachers and support staff wear uniforms. “We don’t want to force something upon the children that we ourselves can’t follow – so we wear uniforms too!” says the FKS Staff. An instance of many measures of instilling a belief of unity and life led by example.

          </span>
                </div>
                <div className="section-image-container fks-force-image-3-container">
                    <img src="/images/fks-force/Didi 3.JPG" className="section-image fks-force-image-3"></img>
                </div>
            </div>



            <div className="section">
                <div className="section-image-container fks-force-image-4-container">
                    <img src="/images/fks-force/Parents - FKS Force.JPG" className="section-image fks-force-image-4"></img>
                </div>
                <div className="section-content mobile-margin">
                    <span className="content-text">
                        <span className="title">PARENT VOLUNTEERS</span>

                        Reinforcing our belief in what we are doing for the children is right, the parent volunteers are our phenomenal motivating force. They inspire us to outshine ourselves every day. Their unflinching faith in us and walking with us in their child’s learning journey as volunteers, mentors and friends has helped us build a coherent world of common understanding, philosophies and foundation for ‘our’ children.

          </span>
                </div>

            </div>

            <div className="curvy-container">
                <div className="curvy-image-1-container">
                    <img src="/images/fks-force/curvy1.jpg" className="curvy-image-1 fks-force-curvy-image-1" />
                </div>
                <div className="curvy-image-2-container">
                    <img src="/images/fks-force/Teachers.JPG" className="curvy-image-2 fks-force-curvy-image-2" />
                </div>
                <div className="curvy-image-3-container">
                    <img src="/images/fks-force/Didi Bhaiyas.JPG" className="curvy-image-3 fks-force-curvy-image-3" />
                </div>
            </div>

            {/* MOBILE CAROUSEL BEGIN */}

            {/* SCREEN 1*/}

            <div className="mobile-picture-gallery-container">

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/curvy1.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(7) }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/fks-force/curvy1.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/fks-force/Teachers.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/fks-force/Didi Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/fks-force/Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 2*/}

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/Teachers.JPG" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/fks-force/curvy1.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/fks-force/Teachers.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/fks-force/Didi Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/fks-force/Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 3*/}

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/Didi Bhaiyas.JPG" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/fks-force/curvy1.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/fks-force/Teachers.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/fks-force/Didi Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/fks-force/Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 4*/}

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/Bhaiyas.JPG" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/fks-force/curvy1.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/fks-force/Teachers.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/fks-force/Didi Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/fks-force/Bhaiyas.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 5*/}

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/6.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/fks-force/6.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/fks-force/3.jpeg.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/fks-force/2.jpeg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/fks-force/1.jpeg.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                    </div>
                </div>

                {/* SCREEN 6*/}

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/3.jpeg.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/fks-force/6.jpg" className="mobile-picture-gallery-disabled-image  " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/fks-force/3.jpeg.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/fks-force/2.jpeg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/fks-force/1.jpeg.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                    </div>
                </div>

                {/* SCREEN 7*/}

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/2.jpeg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/fks-force/6.jpg" className="mobile-picture-gallery-disabled-image  " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/fks-force/3.jpeg.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/fks-force/2.jpeg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/fks-force/1.jpeg.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                    </div>
                </div>
                {/* SCREEN 8*/}

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/fks-force/1.jpeg.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/fks-force/6.jpg" className="mobile-picture-gallery-disabled-image  " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/fks-force/3.jpeg.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/fks-force/2.jpeg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/fks-force/1.jpeg.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default FksForce;
