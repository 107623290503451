import "./vision-and-mission.css";
import React from 'react';

function VisionAndMissionP() {
  return (
    <div className="vision-and-mission">
      <div className="cover">
        <img src="../images/mission-and-vision/M&V Banner.jpg" className="cover-image vision-and-mission-cover-image" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">About Us</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/our-story" className="a-nav-link">Our Story</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/vision-and-mission" className="a-nav-link a-nav-item-selected">FKS Perspective - Philosophy, Vision &amp; Mission</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/fks-force" className="a-nav-link ">FKS Force (Team)</a>
          </span>
        </span>
      </div>

      <div className="section vision-and-mission-section">
        <div className="section-image-container">
          <img src="/playgroup-images/ff-philomissionvission/CT-min.jpg" className="section-image"></img>
        </div>
        <div className="section-content">
          <span className="title">PHILOSOPHY</span>

          Our <b>‘Child-first’</b> philosophy has been our pillar of strength through this fascinating journey speckled with ups and downs – and a lot of learning in the process. <br /><br />

          Children have always been the centre of our ever-expanding universe – where they are <b>‘Schooled for a journey called Life!”</b> The lessons they learn here, the experiences they endure, the relationships they forge stay with them as they graduate to bigger, wider, and more challenging arenas in our dynamic world.<br /><br />

          An environment of learning where kids can be kids and where parents and teachers are only the levers to enable children recognize themselves and enhance their inherent abilities, is the foundation tenet of <b>The Future Kid’s School (FKS)</b>. With focus on the vital life lessons, children are encouraged “To dream the impossible dream” and provide everything that they need to triumph over it! It is truly a school of the children … for the children!
        </div>
      </div>



      <div className="section vision-and-mission-section">
        <div className="section-content">
          <span className="title">VISION &amp; MISSION</span>
          At FKS, children are equipped with the skills to take responsibility, tackle challenges with integrity, maintain dignity in setbacks and courage with conviction to bounce back – creating a solid foundation for their lives. <br/><br/>
          
          Through Integrated learning and developmental methods, the pursuit is education through multiple delivery channels. A child is at the centre of learning. FKS strives to enhance the best qualities in children, understand their inner strength and achieve their personal goals.<br/><br/>

          The FKS fraternity is committed to creating a milieu of stimuli, inquisitiveness and solidarity among students. Gently, but firmly, children are nudged on the path of self-actualization, to realize their true potential and use it to the best of their abilities.


        </div>
        <div className="section-image-container">
          <img src="/playgroup-images/ff-philomissionvission/PV-min.jpg" className="section-image mv-img-2"></img>
        </div>
      </div>


    </div>
  );
}

export default VisionAndMissionP;
