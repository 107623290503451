import React from "react";
import "./main.css";
import { Link } from "react-router-dom";

function Main() {
  function redirectMain() {
    window.location.href = "/main-campus";
  }

  function redirectPg() {
    window.location.href = "/playgroup-jubileehills";
  }

  function redirectPlaygroupNarsingi() {
    window.location.href = "/playgroup-narsingi";
  }

  return (
    <>
      <div className="main">
        {/* Will be hidden on desktop */}

        <div className="main-section-1">
          <div className="main-section-1-left">
            <div className="main-section-1-logo-container">
              <img src="/fks-logo.png" className="main-section-1-logo" />
            </div>
            <div className="main-section-1-text-conatiner">
              <label className="main-section-1-big-text caption-red dare-text">
                <b>DARE</b>
              </label>
              <label className="main-section-1-small-text caption-red to-dream-text">
                <b>to dream &</b>
              </label>
              <label className="main-section-1-big-text caption-blue">
                <b>LEAD</b>
              </label>
              <label className="main-section-1-small-text caption-blue">
                <b>by example</b>
              </label>
            </div>
          </div>
          <div className="main-section-1-right">
            <div className="main-section-1-links-container">
              <div className="main-section-link-heading-row">
                <label className="link-container-heading">
                  School Campuses
                </label>
              </div>
              <div className="link-container-row">
                <div className="link-container-sub-heading">Main Schools</div>
                <div className="link-box-row">
                  <div className="link-box-row-left">
                    <label
                      className="link-box-link-main"
                      onClick={redirectMain}
                    >
                      The Future Kid's School
                    </label>
                    <label className="link-box-sub-link" onClick={redirectMain}>
                      Nanakarmguda
                    </label>
                  </div>
                  {/* <div className="link-box-row-right">
                                        <label className="link-box-link-main" >The Future Kid's High School</label>
                                        <label className="link-box-sub-link">Nizampet</label>
                                    </div> */}
                </div>
              </div>
              <div className="link-container-row">
                <div className="link-container-sub-heading">Playgroups</div>
                <div className="link-box-row">
                  <div className="link-box-row-left">
                    <label className="link-box-link-main" onClick={redirectPg}>
                      The Future
                      <span className="link-box-bold-text"> Kid's </span>
                      Playgroup
                    </label>
                    <label className="link-box-sub-link" onClick={redirectPg}>
                      Jubilee Hills
                    </label>
                    <label
                      className="link-box-sub-link"
                      onClick={() => {
                        window.location.href =
                          "/playgroup-jubileehills/connect";
                      }}
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid #fff",
                        fontWeight: 700,
                      }}
                    >
                      Go to admissions form &gt;
                    </label>
                  </div>
                  <div className="link-box-row-right">
                    <label
                      className="link-box-link-main"
                      onClick={redirectPlaygroupNarsingi}
                    >
                      The Future
                      <span className="link-box-bold-text"> Kid's </span>
                      Playgroup
                    </label>
                    <label
                      className="link-box-sub-link"
                      onClick={redirectPlaygroupNarsingi}
                    >
                      Narsingi
                    </label>
                    <label
                      className="link-box-sub-link"
                      onClick={() => {
                        window.location.href = "/playgroup-narsingi/connect";
                      }}
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid #fff",
                        fontWeight: 700,
                        marginRight: "20px",
                      }}
                    >
                      Go to admissions form &gt;
                    </label>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="main-section-2">
          <div className="main-section-2-left"></div>
          <div className="main-section-2-right">
            <div className="main-section-2-text-conatiner">
              <label className="main-setion-2-poem-text">
                <b>
                  I am not just an edifice set in stone
                  <br />
                  Nor a horde of tutors that merely students I will hone
                  <br />
                  I am not just your set of run-of-the-mill books
                  <br />
                  Or a mirror to divulge how your future looks
                  <br />
                  <br />
                  You may find me ordinary sometimes … but lackluster is never
                  me
                  <br />
                  Your perception of me, however, is a reflection of you in me
                  to see
                  <br />
                  I am a source of infinite energy that binds my dynamic
                  universe
                  <br />
                  Choose to repel, remark or revel in my facade diverse
                  <br />
                  <br />
                  I am the progressive verve that sees the unseen
                  <br />
                  I sense the elusive and achieve the never-been
                  <br />
                  I am an endless source of vitality
                  <br />
                  I am the infinite reality!
                  <br />
                  <br />
                </b>
              </label>
              <label className="main-setion-2-poem-text caption-red">
                <b>
                  I am me!
                  <br />I am the Future Kids School
                </b>
              </label>
            </div>
          </div>
        </div>
        <div className="footer-copyright-container main-page-trademark">
          <label className="footer-copyright-text">
            {" "}
            &copy; 2021 The Future Kid's School.
          </label>
        </div>
      </div>
    </>
  );
}

export default Main;
