import React from "react";
import "./privacy-policy.css";

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <span className="privacy-policy-content">
                <h1 className="privacy-policy-heading">Introduction</h1>
                <p>MKR EDUCATIONAL SOCIETY with the brand name The Future Kid’s School (“FKS”) is an educational institution founded on 8 February 1997 with an objective to give the child an education as a “child would want it,” It was founded by Ms. M Shailaja Rao. It’s a co- education day school, where we work towards making the future generations responsible. Our lessons are based on the CICSE board recommendations, but not limited to them! Our institution envisions more than mere textbook learning. It creates a stimulating and dynamic learning atmosphere. We have uniquely designed programs that hone various facets of children that allow them to blossom at their own pace. This coupled with innovative methods are a great advantage for students.</p>
                <h1 className="privacy-policy-heading">Consent to Privacy Policy</h1>
                <p>MKR Society respects the privacy of all users who access and utilize its website www.thefks.in and other websites associated with such URL (collectively the "Website"). Any Information (as defined below) collected from you throughthe Website shall be governed by this privacy policy (“Privacy Policy”).
                <br/>
                <ul>
                    <li>By accessing the Website; or</li>
                    <li>Registering on the Website; or</li>
                    <li>Availing any services provided by MKR Society through the Website</li> 
                    </ul>
                    you provide an unconditional consent, as required under applicable law, for the collection, usage, disclosure, retention and processing of your Information including Personal Information by MKR Society in accordance with this Privacy Policy. In the event you do not consent to the collection, use, storage and/or disclosure of your Information by MKR Society, please forthwith exit from the Website and discontinue use of the same. Please note that you can also withdraw your consent at any time by writing to MKR Society at <a href="mailto:connect@thefks.in">connect@thefks.in</a>. Such withdrawal of consent willresult in discontinuation of
                    <ul>
                        <li>Your access and utilization of the Website, and </li>
                        <li>The services for which such Information is required.</li>
                    </ul>
    
</p>
<h1 className="privacy-policy-heading">Collection and Storage of Information</h1>
<p>As used in this Privacy Policy, “Information” shall include date, message, text, images, sound, voice, codes, computer programs, software and data bases or microfilm or computer generated microfiche, and “Personal Information” shall mean any information which is capable of identifying you (either directly or indirectly, in combination with other information available or likely to be available with MKR Society.

MKR Society may collect Information including your Personal Information when you (student/parent/guardian etc.) access and utilize its Website for various purposes such as enquiry, registration, participation in survey and discussion forums etc., including but not limited to:
<ul>
    <li>Unique identifiers such as user name, account number, password;</li>    
    <li>Contact information of users such as names, mailing addresses, e-mail addresses, telephone and fax numbers; and</li>
    <li>Demographic information of users such as age, education, gender, interests and pin code.</li>
</ul>
In addition, MKR Society may also collect technical Information in relation to the hardware and software utilized by you such as Internet Protocol (IP) address, browser type, domain names, type of operating system, access times etc. This technical Information is not considered Personal Information except when it is used in conjunction with other Information that may personally identify you.
<br/>
The Information including Personal Information collected from you may be stored and processed by MKR Society in Indiaor any other city/country in which MKR Society or its corporate affiliates, subsidiaries or agents maintain facilities that ensure the same level of data protection that is adhered to by MKR Society. You shall be deemed to have consented for transfer ofsuch Information outside your country if you provide MKR Society your consent for collection and use of your Personal Information in terms of this Privacy Policy.
<br/>
Please note that MKR Society will not be responsible for the authenticity of any Information supplied by you and any losses incurred as a result of such incorrect Information will be solely attributable to you.

</p>
<h1 className="privacy-policy-heading">Use and Disclosure of Information</h1>
<p>MKR Society collects and uses your Information including Personal Information for any or all of the following purposes:
    <ul>
        <li>Conduct of administerial and managerial services by MKR Society;</li>        
        <li>Provision of information in relation to services offered by MKR Society;</li>
        <li>Sending you newsletters, advertisements and notifications;</li>
        <li>Apprising you of events conducted by MKR Society,</li>
        <li>to conduct surveys or other research in relation to services offered by MKR Society and</li>
        <li>any other purposes related thereto or deemed relevant by MKR Society.</li>
    </ul>

Please note that MKR Society may disclose your Information including any sensitive personal data or Personal Informationcollected from you to third parties such as affiliates, business partners, vendors, service providers etc. for the sole purpose of providing services to you subject to such third parties being bound by confidentiality obligations similar to the confidentiality obligations of MKR Society under this Privacy Policy, prior to such disclosure to such third party. MKR Society, may at its sole discretion, disclose your Information, including any sensitive personal data or Personal Information, in compliance with any applicable law or any notice/ order of any statutory, regulatory or judicial authorityand/or to protect and defend the rights or property of MKR Society and/or under exigent circumstances in order to protect the personal safety of users of the Website. Any such disclosure of your Information by MKR Society shall be deemed to bea disclosure with your prior consent and agreement.
</p>
<h1 className="privacy-policy-heading">Security</h1>
<p>MKR Society is committed to ensure that your Information is secure and protected at all times. MKR Society undertakes commercially reasonable efforts to secure your Information from unauthorized access, use, or disclosure and has in place appropriate physical, electronic and managerial procedures to safeguard and secure the Information collected through the Website, in accordance with applicable law including Information Technology Act 2000 and Information Technology (Reasonable security practices and procedures and sensitive personal data or Information) Rules 2011 and any prevailing industry standards.
While MKR Society strives to protect all Information provided by you, MKR Society does not ensure, warrant or guarantee the continued privacy, confidentiality or security of your Information including Personal Information, and therefore any Information provided by you shall be at your own risk.
</p>
<h1 className="privacy-policy-heading">Third Party Sites</h1>
<p>MKR Society’s Website may contain links to third party websites and features. This Privacy Policy does not cover the privacy practices of such third parties and MKR Society disclaims any responsibility or liability in respect of such third party websites, features or policies.</p>
<h1 className="privacy-policy-heading">Cookies</h1>
<p>Please note that MKR Society’s Website uses "cookies" (information stored on your computer by your browser at our request). Cookies enable the Website to "remember" who you are, enabling you to access and utilize the Website in a more efficient manner. MKR Society may use cookies to store some of your preferences (including limited Information) orto display relevant content based upon what you view on our Website.</p>
<h1 className="privacy-policy-heading">Updating Your Personal Information</h1>
<p>You may review, correct, update, or delete your Personal Information maintained by MKR Society at any time. Please provide with sufficient proof of your identity and appropriate documentation of corrections to be made by writing to MKR Society on <a href="mailto:connect@thefks.in">connect@thefks.in</a></p>
<h1 className="privacy-policy-heading">Notification of Changes in Privacy Policy</h1>
<p>The Privacy Policy may be amended by MKR Society from time to time. MKR Society may notify you of any changes by posting the new Privacy Policy on the Website. You are advised to review this Privacy Policy periodically for any changes.</p>
<h1 className="privacy-policy-heading">Governing Law</h1>
<p>This Privacy Policy shall, in all respects, be governed by and construed in all respect in accordance with the laws of India. You hereby agree to submit to the exclusive jurisdiction of the courts at Hyderabad in connection with any dispute arising out of or in connection with these terms.

Please note that in no event will MKR Society be liable or responsible to you in any manner whatsoever for any remote, indirect, consequential, punitive, exemplary or special loss or damage, even if we both were aware of such losses upfront.
</p>
            </span>
        </div>
    )
}

export default PrivacyPolicy;