import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import xlsx from "json-as-xlsx";
import { useEffect, useState } from "react";

export default function Admin() {
  const [passed, setPassed] = useState(false);
  const [accessKey, setAccessKey] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("JBH");
  const [rows, setRows] = useState([]);
  async function getData() {
    const res = await axios.get(
      `https://fks-server.vercel.app/api/getQueries/${selectedBranch}`
    );
    if (res.data.status) {
      setRows(res.data.data);
    }
  }

  useEffect(() => {
    getData(selectedBranch);
  }, [selectedBranch]);

  function checkAccessKey() {
    if (accessKey === "PGFKS2023") {
      setPassed(true);
    } else {
      setPassed(false);
      alert("invalid");
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ccc",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function exportXLS() {
    let data = [
      {
        sheet: "form data",
        columns: [
          { label: "SNO", value: "sno" },
          { label: "Registration Number", value: "registrationNumber" },
          { label: "Submission Date", value: "submissionDate" },
          { label: "Name", value: "name" },
          { label: "Gender", value: "gender" },
          { label: "Date Of Birth", value: "dob" },
          { label: "Grade", value: "grade" },
          { label: "Residential Address", value: "address" },
          { label: "Father's Name", value: "fathersName" },
          { label: "Father's Email Address", value: "fathersEmailAddress" },
          { label: "Father's Mobile Number", value: "fathersMobileNumber" },
          { label: "Father's Qualification", value: "fathersQualification" },
          { label: "Father's Occupation", value: "fathersOccupation" },
          { label: "Mother's Name", value: "mothersName" },
          { label: "Mother's Email Address", value: "mothersEmailAddress" },
          { label: "Mother's Mobile Number", value: "mothersMobileNumber" },
          { label: "Mother's Qualification", value: "mothersQualification" },
          { label: "Mother's Occupation", value: "mothersOccupation" },
          { label: "Comments", value: "comments" },
          { label: "Source", value: "source" },
        ],
        content: (() => {
          var arr = [];
          rows.forEach((row, i) => {
            arr.push({
              sno: i + 1,
              registrationNumber: `${selectedBranch}-2026-${row.submissionId}`,
              submissionDate: new Date(row.date).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
              name: row.name,
              gender: row.gender,
              dob: new Date(row.dob).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
              grade: row.grade,
              address: row.address,
              fathersName: row.fathersName,
              fathersEmailAddress: row.fathersEmailAddress,
              fathersMobileNumber: row.fathersMobileNumber,
              fathersOccupation: row.fathersOccupation,
              fathersQualification: row.fathersQualification,
              mothersName: row.mothersName,
              mothersEmailAddress: row.mothersEmailAddress,
              mothersMobileNumber: row.mothersMobileNumber,
              mothersOccupation: row.mothersOccupation,
              mothersQualification: row.mothersQualification,
              comments: row.comments,
              source: row.source,
            });
          });
          return arr;
        })(),
      },
    ];

    let settings = {
      fileName: `${selectedBranch}-form-data`, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    xlsx(data, settings);
  }

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        minHeight: "100vh",
        padding: "50px 100px",
      }}
    >
      {passed ? (
        <>
          {/* buttons to select branch */}
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                setSelectedBranch("JBH");
              }}
            >
              Jubilee Hills
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                setSelectedBranch("NSG");
              }}
            >
              Narsingi
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                exportXLS();
              }}
            >
              Export XLS
            </Button>
          </Stack>
          <Typography sx={{ marginTop: "20px", fontSize: "14px" }}>
            Showing results for {selectedBranch}
          </Typography>
          <TableContainer component={Paper}>
            <TableHead>
              <StyledTableRow sx={{ backgroundColor: "#ccc" }}>
                <StyledTableCell>SNO</StyledTableCell>
                <StyledTableCell>Registration Number</StyledTableCell>
                <StyledTableCell>Submission Date</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Gender</StyledTableCell>
                <StyledTableCell>Date Of Birth</StyledTableCell>
                <StyledTableCell>Grade</StyledTableCell>
                <StyledTableCell>Child's Residential Address</StyledTableCell>
                <StyledTableCell>Father's Name</StyledTableCell>
                <StyledTableCell>Father's Email Address</StyledTableCell>
                <StyledTableCell>Father's Mobile Number</StyledTableCell>
                <StyledTableCell>Father's Qualification</StyledTableCell>
                <StyledTableCell>Father's Occupation</StyledTableCell>
                <StyledTableCell>Mother's Name</StyledTableCell>
                <StyledTableCell>Mother's Email Address</StyledTableCell>
                <StyledTableCell>Mother's Mobile Number</StyledTableCell>
                <StyledTableCell>Mother's Qualification</StyledTableCell>
                <StyledTableCell>Mother's Occupation</StyledTableCell>
                <StyledTableCell>Comments</StyledTableCell>
                <StyledTableCell>Source</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <StyledTableRow>
                    <StyledTableCell>{i + 1}</StyledTableCell>
                    <StyledTableCell>{`${selectedBranch}-2026-${row.submissionId}`}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(row.date).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.gender}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(row.dob).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </StyledTableCell>
                    <StyledTableCell>{row.grade}</StyledTableCell>
                    <StyledTableCell>{row.address}</StyledTableCell>
                    <StyledTableCell>{row.fathersName}</StyledTableCell>
                    <StyledTableCell>{row.fathersEmailAddress}</StyledTableCell>
                    <StyledTableCell>{row.fathersMobileNumber}</StyledTableCell>
                    <StyledTableCell>
                      {row.fathersQualification}
                    </StyledTableCell>
                    <StyledTableCell>{row.fathersOccupation}</StyledTableCell>
                    <StyledTableCell>{row.mothersName}</StyledTableCell>
                    <StyledTableCell>{row.mothersEmailAddress}</StyledTableCell>
                    <StyledTableCell>{row.mothersMobileNumber}</StyledTableCell>
                    <StyledTableCell>
                      {row.mothersQualification}
                    </StyledTableCell>
                    <StyledTableCell>{row.mothersOccupation}</StyledTableCell>
                    <StyledTableCell>{row.comments}</StyledTableCell>
                    <StyledTableCell>{row.source}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </TableContainer>
        </>
      ) : (
        <Box>
          <Stack spacing={1} sx={{ marginTop: "100px", width: "fit-content" }}>
            <Typography>Enter access key to continue</Typography>

            <input
              type="text"
              value={accessKey}
              onChange={(e) => {
                e.preventDefault();
                setAccessKey(e.target.value);
              }}
              style={{ width: "300px" }}
            />
            <Button
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                checkAccessKey();
              }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
