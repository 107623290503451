import React from "react";
import "./notice-banner.css";

import Marquee from "react-fast-marquee";

function NoticeBannerMainCampus() {
  return (
    <div className="notice-banner">
      <Marquee gradient={false} speed={80}>
        <label className="notice-label">
          <b>
          Admissions for Grade 1 till Grade 9 (academic year 2025 - 2026) are closed for now. Registrations may open in February 2025 in case of vacancies
          </b>
        </label>
      </Marquee>
    </div>
  );
}

export default NoticeBannerMainCampus;
