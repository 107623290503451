import { useState, useEffect } from 'react';
import "./campus.css";

function Campus() {

    const [selected, setSelected] = useState(0)

    useEffect(() => {
        for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
            document.getElementsByClassName('sports-carousel-item')[i].style.display = "none";
        }
        document.getElementsByClassName('sports-carousel-item')[selected].style.display = "flex";
    }, [selected])

    function carouselNext() {
        for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
            if (selected == 7) {
                break;
            }
            document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
            document.getElementsByClassName('sports-carousel-item')[selected + 1].style.display = "flex";
            setSelected(selected + 1)

        }
    }

    function carouselPrev() {
        for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
            if (selected == 0) {
                break;
            }
            document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
            document.getElementsByClassName('sports-carousel-item')[selected - 1].style.display = "flex";
            setSelected(selected - 1)
        }
    }


    /*MOBILE GALLERY LOGIC */

    const [selectedImg, setSelectedImg] = useState(0)

    // useEffect(() => {
    //     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
    //         document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
    //     }
    //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
    // }, [selectedImg])

    // function mobCarouselNext() {
    //     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
    //         if (selectedImg == 7) {
    //             break;
    //         }
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
    //         setSelectedImg(selectedImg + 1)

    //     }
    // }

    // function mobCarouselPrev() {
    //     for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
    //         if (selectedImg == 0) {
    //             break;
    //         }
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
    //         document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
    //         setSelectedImg(selectedImg - 1)
    //     }
    // }


    return (
        <div className="campus">
            <div className="cover">
                <img src="/images/campus/cover-image.jpg" className="cover-image campus-cover" />
            </div>

            <div className="section">
                <div className="section-content text-align-center">
                    <span className="content-text">
                        <span className="title">CAMPUS</span>
                        How rightly has Julia Morgan said, “Architecture is a visual art, and the buildings speak for themselves!” Walk through the gates of FKS campus and you will know how talkative and expressive the robust stone structure of FKS is! Look closely and you will find stories etched in eve.<br /><br />
                        By aligning it to the landscape and environment it was born in, the FKS building is built among the natural rock and lush greenery of the land and façades perfectly in its settings. We have adopted environment-friendly construction practices through un-plastered, load-bearing fly-ash, brick masonry walls and RCC surfaces.

          </span>
                </div>
                <div className="section-image-container">
                    <img src="/images/campus/_RA_0254_FKS.JPG" className="section-image campus-image-1"></img>
                </div>
            </div>

            <div className="section">
                <div className="section-image-container">
                    <img src="/images/campus/_RA_0185_FKS.JPG" className="section-image campus-image-2"></img>
                </div>
                <div className="section-content text-align-center">
                    <span className="content-text">
                        Allowing topography to lead architectural design and implementation, are 3 distinct structures for primary, middle school and high school integrating seamlessly through the landscape. These dainty buildings create a cohesive learning space between them that accommodates children and offers each phase of learning, exactly what it needs in terms of infrastructure. <br /><br />
                        Our space is a continuous work-in-progress, where our children help us plan, plant and care look after the landscape that consists of a variety of ever-evolving flora and fauna – a vital step in creating environmental awareness among them.

          </span>
                </div>
            </div>

            <div className="section">
                <div className="section-content text-align-center">
                    <span className="content-text">
                        With the outside world, we are connected through a wide network of school buses and transportation vans and inside, we are connected to one another through a seamless flow of spaces. We boast of a staff-room free school, classrooms with abundant natural light and ventilation and open spaces for children to use freely.<br /><br />
                        Choosing constructive over the destructive, including over excluding and being environment-friendly, FKS has kept in mind its inhabitants – the children – at the center of its philosophy always – and designed its spaces keeping in mind their age-groups, the climate and the maximum freedom that can be made available to them for their unhindered growth.

          </span>
                </div>
                <div className="section-image-container mobile-margin">
                    <img src="/images/campus/_MG_0093.jpg" className="section-image campus-image-3"></img>
                </div>
            </div>

            {/* <div className="curvy-container">
                <div className="curvy-image-1-container">
                    <img src="/images/campus/_RA_0212_FKS.JPG" className="curvy-image-1 campus-curvy-image-1" />
                </div>
                <div className="curvy-image-2-container">
                    <img src="/images/campus-new/image-4.JPG" className="curvy-image-2 campus-curvy-image-2" />
                </div>
                <div className="curvy-image-3-container">
                    <img src="/images/campus/20140823_122815.jpg" className="curvy-image-3 campus-curvy-image-3" />
                </div>
            </div> */}

            <div className="curvy-container new">
                {/* CAROUSEL - 1 */}
                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/View 4.jpg" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/_RA_0211_FKS.JPG" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(7) }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/_RA_0232_FKS.JPG" className="sports-image-disabled"></img>
                    </div>
                </div>

                {/* CAROUSEL - 2 */}

                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/_RA_0211_FKS.JPG" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/_RA_0232_FKS.JPG" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/_RA_0344_FKS.JPG" className="sports-image-disabled"></img>
                    </div>
                </div>

                {/* CAROUSEL - 3 */}


                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/_RA_0232_FKS.JPG" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/_RA_0344_FKS.JPG" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/20140823_122815.jpg" className="sports-image-disabled"></img>
                    </div>
                </div>

                {/* CAROUSEL - 4 */}


                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/_RA_0344_FKS.JPG" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/20140823_122815.jpg" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/DSC_0132.JPG" className="sports-image-disabled"></img>
                    </div>
                </div>

                {/* CAROUSEL - 5 */}


                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/20140823_122815.jpg" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/DSC_0132.JPG" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/View 2.jpg" className="sports-image-disabled"></img>
                    </div>
                </div>

                {/* // CAROUSEL - 6 */}


                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/DSC_0132.JPG" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/View 2.jpg" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/View 3.jpg" className="sports-image-disabled"></img>
                    </div>
                </div>

                {/* // CAROUSEL - 7 */}


                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/View 2.jpg" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/View 3.jpg" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/View 4.jpg" className="sports-image-disabled"></img>
                    </div>
                </div>

                {/* CAROUSEL - 8 */}

                <div className="sports-carousel-item">
                    <div className="sports-carousel-item-left">
                        <img src="/images/campus/View 3.jpg" className="sports-image-disabled"></img>
                    </div>
                    <div className="sports-carousel-item-center">
                        <img src="/images/campus/View 4.jpg" className="sports-image-focus"></img>
                        <div className="sports-carousel-buttons-container">
                            <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
                            <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(0) }} />
                        </div>
                    </div>
                    <div className="sports-carousel-item-right">
                        <img src="/images/campus/_RA_0211_FKS.JPG" className="sports-image-disabled"></img>
                    </div>
                </div>
            </div>

            {/* MOBILE CAROUSEL BEGIN */}

            {/* SCREEN 1*/}

            {/* <div className="mobile-picture-gallery-container">

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/_RA_0211_FKS.JPG" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(7) }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/campus/_RA_0211_FKS.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/campus/_RA_0232_FKS.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/campus/_RA_0344_FKS.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/campus/20140823_122815.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 2*

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/_RA_0232_FKS.JPG" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/campus/_RA_0211_FKS.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/campus/_RA_0232_FKS.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/campus/_RA_0344_FKS.JPG" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/campus/20140823_122815.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 3

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/_RA_0344_FKS.JPG" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/campus/_RA_0211_FKS.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/campus/_RA_0232_FKS.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/campus/_RA_0344_FKS.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/campus/20140823_122815.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 4

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/20140823_122815.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
                            <img src="/images/campus/_RA_0211_FKS.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
                            <img src="/images/campus/_RA_0232_FKS.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
                            <img src="/images/campus/_RA_0344_FKS.JPG" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
                            <img src="/images/campus/20140823_122815.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                    </div>
                </div>

                {/* SCREEN 5*

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/DSC_0132.JPG" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
                        <div className="mobile-gallery-button-container">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/campus/DSC_0132.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/campus/View 2.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/campus/View 3.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/campus/View 4.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                    </div>
                </div>

                {/* SCREEN 6

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/View 2.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container campus-gallery-image-button-align-1">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/campus/DSC_0132.JPG" className="mobile-picture-gallery-disabled-image  " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/campus/View 2.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/campus/View 3.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/campus/View 4.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                    </div>
                </div>

                {/* SCREEN 7

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/View 3.jpg" className="mobile-picture-gallery-focus-image" />
                        <div className="mobile-gallery-button-container campus-gallery-image-button-align-2">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/campus/DSC_0132.JPG" className="mobile-picture-gallery-disabled-image  " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/campus/View 2.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/campus/View 3.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/campus/View 4.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                    </div>
                </div>
                {/* SCREEN 8

                <div className="mobile-picture-gallery">
                    <div className="mobile-gallery-focus-image-container">
                        <img src="/images/campus/View 4.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
                        <div className="mobile-gallery-button-container campus-gallery-image-button-align-2">
                            <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
                            <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
                        </div>
                    </div>
                    <div className="mobile-gallery-disabled-image-container">
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
                            <img src="/images/campus/DSC_0132.JPG" className="mobile-picture-gallery-disabled-image  " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
                            <img src="/images/campus/View 2.jpg" className="mobile-picture-gallery-disabled-image" />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
                            <img src="/images/campus/View 3.jpg" className="mobile-picture-gallery-disabled-image " />
                        </div>
                        <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
                            <img src="/images/campus/View 4.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    );
}

export default Campus;
