import { useState, useEffect } from 'react';
import "./events-and-shows.css";

function EventsAndShows() {

  // const [selected, setSelected] = useState(0)

  // useEffect(() => {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     document.getElementsByClassName('sports-carousel-item')[i].style.display = "none";
  //   }
  //   document.getElementsByClassName('sports-carousel-item')[selected].style.display = "flex";
  // }, [selected])

  // function carouselNext() {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     if (selected == 5) {
  //       break;
  //     }
  //     document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
  //     document.getElementsByClassName('sports-carousel-item')[selected + 1].style.display = "flex";
  //     setSelected(selected + 1)

  //   }
  // }

  // function carouselPrev() {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     if (selected == 0) {
  //       break;
  //     }
  //     document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
  //     document.getElementsByClassName('sports-carousel-item')[selected - 1].style.display = "flex";
  //     setSelected(selected - 1)
  //   }
  // }

  // /*MOBILE GALLERY LOGIC */

  // const [selectedImg, setSelectedImg] = useState(0)

  // useEffect(() => {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
  //   }
  //   document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
  // }, [selectedImg])

  // function mobCarouselNext() {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     if (selectedImg == 5) {
  //       break;
  //     }
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
  //     setSelectedImg(selectedImg + 1)

  //   }
  // }

  // function mobCarouselPrev() {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     if (selectedImg == 0) {
  //       break;
  //     }
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
  //     setSelectedImg(selectedImg - 1)
  //   }
  // }


  return (
    <div className="events-and-shows">

      <div className="cover">
        <img src="/new-images/Event Banner 1.png" className="cover-image events-and-shows-cover-image" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Beyond Learning</span>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/leadership" className="a-nav-link">Leadership at FKS</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/sports-and-games" className="a-nav-link ">Sports, Games &amp; Physical Activities</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/events-and-festivals" className="a-nav-link a-nav-item-selected">Events &amp; Festivals</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/theatre-and-drama" className="a-nav-link">Performing Art</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/field-trips" className="a-nav-link">Field Trips</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content text-align-center">
          <span className="title">EVENTS &amp; SHOWS</span>
          Events are an integral feature of the FKS philosophy to build organization and management skills. They provide each student an avenue to discover, test and hone their talents and abilities.<br /><br />
          Events instill a sense of ownership and appreciation for all. Students hence realize the importance of a team, imbibe a sense of dignity and appreciate the power of hard work.

        </div>
        <div className="section-image-container ">
          <img src="/images/events-and-shows/sk_1030 future kids_.jpg" className="section-image events-and-shows-image-1"></img>
        </div>
      </div>


      <div className="section">
        <div className="section-image-container events-and-shows-image-2-container">
          <img src="/images/events-and-shows/sk_1177 future kids_.jpg" className="section-image events-and-shows-image-2"></img>
        </div>
        <div className="section-content text-align-center">
          Over the past two decades, The FKS force and students have conceptualized and hosted many events, such as, fairs, exhibitions, shows etc. Each year, the surplus from these shows support various social and charitable initiatives identified by the students.

        </div>
      </div>


      <div className="section dual-image-section main-events-and-festivals-dual-img-sect">
        <div className="section-image-container fks-force-image-dual-container festivals-image-4-container">
          <img src="/images/events-and-shows/_MG_4328.jpg" className="section-image fks-force-image-3"></img>
        </div>
        <div className="section-content text-align-center">
          <span className="title">FESTIVALS &amp; CELEBRATIONS</span>
          At FKS we celebrate life in all its nuances. No matter what the religion, caste, creed or economic background, we encourage the feeling of oneness of humanity and use every opportunity to bring out the joy of childhood and innocence.<br /><br />
          Festivals are a special and most beautiful way to demonstrate ‘Unity in Diversity’ to our children – a boon of a culturally rich and vibrant country called India! FKS is a world beyond religions and faith and we welcome each festival with zeal and day-long celebrations with activities, programs and lots of good food!

        </div>
        <div className="section-image-container festivals-image-3-container mobile-margin">
          <img src="/images/events-and-shows/_MG_2754.jpg" className="section-image events-and-shows-image-3"></img>
        </div>
      </div>

      <div className="curvy-container">
        <div className="curvy-image-1-container">
          <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="curvy-image-1 leadership-curvy-image-1" />
        </div>
        <div className="curvy-image-2-container">
          <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="curvy-image-2 leadership-curvy-image-2" />
        </div>
        <div className="curvy-image-3-container">
          <img src="/images/events-and-shows/_MG_4968.jpg" className="curvy-image-3 our-story-curvy-image-3" />
        </div>
      </div>

      {/* <div className="curvy-container new">
        {/* CAROUSEL - 1 
        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/events-and-shows/Stage 0.png.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(5) }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/events-and-shows/_MG_4968.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 2 

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/events-and-shows/_MG_4968.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 3 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/events-and-shows/_MG_4968.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/events-and-shows/sk_1821future kids.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 4 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/events-and-shows/sk_1821future kids.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/events-and-shows/DSCF0196.jpg" className="sports-image-disabled"></img>
          </div>
        </div>


        {/* CAROUSEL - 6 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/events-and-shows/sk_1821future kids.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/events-and-shows/DSCF0196.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/events-and-shows/Stage 0.png.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 7 

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/events-and-shows/DSCF0196.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/events-and-shows/Stage 0.png.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container ">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(0) }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="sports-image-disabled"></img>
          </div>
        </div>
      </div> */}

      {/* MOBILE CAROUSEL BEGIN */}

      {/* SCREEN 1*/}

      {/* <div className="mobile-picture-gallery-container">

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="mobile-picture-gallery-focus-image mobile-gallery-events-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(5) }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/events-and-shows/_MG_4968.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/events-and-shows/sk_1821future kids.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 2*

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/events-and-shows/_MG_4968.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/events-and-shows/_MG_4968.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/events-and-shows/sk_1821future kids.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 3*

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/events-and-shows/_MG_4968.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/events-and-shows/sk_1821future kids.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 4

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/events-and-shows/sk_1821future kids.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/events-and-shows/_MG_4968.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/events-and-shows/sk_0053 future kids_.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/events-and-shows/sk_1821future kids.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>

        {/* SCREEN 5

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/events-and-shows/DSCF0196.jpg" className="mobile-picture-gallery-focus-image mobile-gallery-events-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/events-and-shows/DSCF0196.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/events-and-shows/Stage 0.png.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/events-and-shows/_MG_4968.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 6

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/events-and-shows/Stage 0.png.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/events-and-shows/DSCF0196.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/events-and-shows/Stage 0.png.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/events-and-shows/IMG_20181102_141443.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/events-and-shows/_MG_4968.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default EventsAndShows;
