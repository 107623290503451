import { useState, useEffect } from 'react';
import "./pedagogy.css";

function Pedagogy() {

  /*MOBILE GALLERY LOGIC */

  const [selectedImg, setSelectedImg] = useState(0)

  // useEffect(() => {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
  //   }
  //   document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
  // }, [selectedImg])

  // function mobCarouselNext() {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     if (selectedImg == 7) {
  //       break;
  //     }
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
  //     setSelectedImg(selectedImg + 1)

  //   }
  // }

  // function mobCarouselPrev() {
  //   for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
  //     if (selectedImg == 0) {
  //       break;
  //     }
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
  //     document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
  //     setSelectedImg(selectedImg - 1)
  //   }
  // }



  return (
    <div className="pedagogy">
      <div className="cover">
        <img src="/images/pedagogy/14.jpg" className="cover-image pedagogy-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Learning at FKS</span>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/board-and-curriculum" className="a-nav-link">Board &amp; Curriculum</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/pedagogy" className="a-nav-link a-nav-item-selected">Pedagogy</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/extra-activities" className="a-nav-link">Extra &amp; Co-curricular Activities</a>
          </span>
        </span>
      </div>


      <div className="section">
        <div className="section-content pedagogy-section-content">
          <span className="content-text ">
            <span className="title">PEDAGOGY</span>
            Foundation of FKS Pedagogy are the 3Es. Environment, Exploration and Experience
            <div className="pedagogy-3e-container">
              <div className="pedagogy-3e-image-container">
                <img src="/icons/environment.png" className="pedagogy-3e-image" />
              </div>
              <div className="pedagogy-3e-content">
                <span className="pedagogy-3e-title">Environment</span>
                FKS is a space which would bend backwards for the child. An environment where a child will find her/him self  to be their own best version.  In this environment, spaces are safe for learning and growing, looking inward as much as outward just like their learning. They are rugged at the same time, giving grounded-ness to growing children.
              </div>
            </div>
            <div className="pedagogy-3e-container">
              <div className="pedagogy-3e-image-container">
                <img src="/icons/explore.png" className="pedagogy-3e-image" />
              </div>
              <div className="pedagogy-3e-content">
                <span className="pedagogy-3e-title">Exploration</span>
                The environment allows students to explore it by themselves. They learn to accept, get to voice their opinion, and make choices about their everyday situations. Hands-on learning helps students explore who they are and who they are becoming in the process.
FKS does not set any benchmark, no preconceived notions but gives them an environment that is safe and non-judgmental.

              </div>
            </div>
            <div className="pedagogy-3e-container">
              <div className="pedagogy-3e-image-container">
                <img src="/icons/experience.png" className="pedagogy-3e-image" />
              </div>
              <div className="pedagogy-3e-content">
                <span className="pedagogy-3e-title">Experience</span>
                Our effort is to encourage each person to believe, love and stand up for themself. The FKS experience makes students believe that everybody is equal and deserves equal opportunities. As kids, they learn to appreciate one and all. Students collectively experience oneness, equality, and respect from and with their classmates, teachers, and didi-bhaiyas.
              </div>
            </div>
          </span>
        </div>
      </div>

      <div className="section dual-image-section">
        <div className="section-image-container fks-force-image-dual-container pedagogy-image-2-container">
          <img src="/images/pedagogy/Pedagogy_Learning.JPG" className="section-image pedagogy-image-2"></img>
        </div>
        <div className="section-content text-align-center">
          <span className="content-text ">
            The FKS approach caters to different kinds of learners. It's highly flexible, comprehensive, relatable and hands on! FKS believes in an inclusive approach. The Special ED team constantly enhances the programme.<br /><br />
            Playgroup (Playschool to UKG) practices a thematic approach. Primary School (Grade 1 – 4) has an integrated system with the FKS designed curriculum with no textbooks. <br /><br />
            Students move into the Middle School (Grade 5 – 7) with ease. Textbooks are introduced here. Assessments are designed for different kinds of learners. Teachers get a clear understanding of what has worked for each child and what has not. <br /><br />
            Assessments commence from LKG, in a climate that enables the student to like the journey and is left with a positive experience.

          </span>
        </div>
        <div className="section-image-container pedagogy-image-1-container">
          <img src="/images/pedagogy/9.jpg" className="section-image"></img>
        </div>
      </div>

      <div className="section">
        {/* <div className="section-image-container pedagogy-image-2-container">
          <img src="./pedagogy/8.jpg" className="pedagogy-section-image"></img>
        </div> */}
        <div className="section-content mobile-margin">
          <span className="content-text">
            <span className="title">SPECIAL EDUCATION</span>
            Even before the world acknowledged, The Future Kid's Philosophy was always inclusive. The Special Ed Team along with the teaching faculty designs an intensive remedial program that is tailor made to suit the  diverse learning needs, to consolidate their knowledge, strengthen their confidence &amp; enhance the effectiveness of learning.
          </span>
        </div>
      </div>

      <div className="curvy-container">
        <div className="curvy-image-1-container">
          <img src="/images/pedagogy/ko.jpg" className="curvy-image-1 pedagogy-curvy-image-1" />
        </div>
        <div className="curvy-image-2-container pedagogy-curvy-image-2-container">
          <img src="/images/pedagogy/peda.jpg" className="curvy-image-2 pedagogy-curvy-image-2" />
        </div>
        <div className="curvy-image-3-container">
          <img src="/images/pedagogy/10.jpg" className="curvy-image-3 pedagogy-curvy-image-3" />
        </div>
      </div>

      {/* MOBILE CAROUSEL BEGIN */}

      {/* SCREEN 1*/}

      {/* <div className="mobile-picture-gallery-container">

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/ko.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(7) }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/pedagogy/ko.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/pedagogy/peda.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/pedagogy/10.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/pedagogy/17.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 2*

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/peda.jpg" className="mobile-picture-gallery-focus-image pedagogy-mobile-galery-img-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/pedagogy/ko.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/pedagogy/peda.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/pedagogy/10.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/pedagogy/17.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 3*

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/10.jpg" className="mobile-picture-gallery-focus-image pedagogy-mobile-galery-img-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/pedagogy/ko.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/pedagogy/peda.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/pedagogy/10.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/pedagogy/17.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>
        {/* SCREEN 4

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/17.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/pedagogy/ko.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/pedagogy/peda.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/pedagogy/10.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/pedagogy/17.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>

        {/* SCREEN 5

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/12.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/pedagogy/12.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/pedagogy/8.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/pedagogy/4.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/pedagogy/3.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 6

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/8.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/pedagogy/12.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/pedagogy/8.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/pedagogy/4.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/pedagogy/3.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 7

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/4.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/pedagogy/12.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/pedagogy/8.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/pedagogy/4.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/pedagogy/3.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>
        {/* SCREEN 8

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/pedagogy/3.jpg" className="mobile-picture-gallery-focus-image sport-gallery-image-align" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/pedagogy/12.jpg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/pedagogy/8.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/pedagogy/4.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/pedagogy/3.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default Pedagogy;
