import React, { useState, useEffect } from 'react';

import "./play-at-fks.css";


function PlayAtFKSP() {

  return (
    <div className="sports-and-games">

      <div className="cover">
        <img src="/playgroup-images/beyond-learning/Banner 1-min.jpg" className="cover-image play-at-fks-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Beyond Learning</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/well-being" className="a-nav-link ">Well-Being</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/play-at-fks" className="a-nav-link a-nav-item-selected">Play At FKS</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/events-and-festivals" className="a-nav-link ">Events &amp; Festivals</a>
          </span>
        </span>
      </div>


      <div className="section dual-image-section pg-fks-force-dual-img-sect">
                <div className="section-image-container fks-force-image-dual-container pg-play-at-fks-dual-img1-container">
                    <img src="/playgroup-images/beyond-learning/1-min.png" className="section-image pg-play-at-fks-dual-img1"></img>
                </div>
                <div className="section-content">
                    <span className="content-text">
                        <span className="title">PLAY AT FKS</span>

                        Play is the very cornerstone of our program in FKS. We believe PLAY is integral to learning, where the child is deeply involved and in the process discovers himself. There is a balance of active and passive activities. Number, alphabet games allow children to reapply concerts; Free play nurtures social and emotional development while strengthening motor and physical growth. <br /><br />

Some parts of the play are free and flowing, others focus on getting children to understand boundaries, rules, the give and take involved while playing.<br /><br />

The trips to the big school of FKS for their sports day is a big event every year for them.
          </span>
                </div>
                <div className="section-image-container fks-force-image-3-container">
                    <img src="/playgroup-images/beyond-learning/6-min.png" className="section-image fks-force-image-3"></img>
                </div>
            </div>


      

    </div>
  );
}

export default PlayAtFKSP;
