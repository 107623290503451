import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import AppRoutes from "./routes";

function App() {
  useEffect(() => {
    const pf = navigator.platform;
    if (pf.match(/iPad/)) {
      document.getElementById("body-id").style.backgroundImage =
        "linear-gradient(to right, #E3F2F5,  #E3F2F5)";
    }
  }, []);
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
