import { useState, useEffect } from 'react';
import "./extra-activities.css";

function ExtraActivities() {

  // const [selected, setSelected] = useState(0)

  // useEffect(() => {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     document.getElementsByClassName('sports-carousel-item')[i].style.display = "none";
  //   }
  //   document.getElementsByClassName('sports-carousel-item')[selected].style.display = "flex";
  // }, [selected])

  // function carouselNext() {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     if (selected == 6) {
  //       break;
  //     }
  //     document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
  //     document.getElementsByClassName('sports-carousel-item')[selected + 1].style.display = "flex";
  //     setSelected(selected + 1)

  //   }
  // }

  // function carouselPrev() {
  //   for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
  //     if (selected == 0) {
  //       break;
  //     }
  //     document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
  //     document.getElementsByClassName('sports-carousel-item')[selected - 1].style.display = "flex";
  //     setSelected(selected - 1)
  //   }
  // }


  return (
    <div className="extra-activities">
      <div className="cover">
        <img src="/images/extra-activities/Banner 2.JPG" className="cover-image extra-activities-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Learning at FKS</span>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/board-and-curriculum" className="a-nav-link">Board &amp; Curriculum</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/pedagogy" className="a-nav-link">Pedagogy</a>
          </span>
          <span className="a-nav-item">
            <a href="/main-campus/extra-activities" className="a-nav-link a-nav-item-selected">Extra &amp; Co-curricular Activities</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content">
          <span className="title">Extra &amp; Co-curricular Activities</span>
          FKS recognizes the importance of co-curricular activities in encouraging holistic development. Our syllabus incorporates various activities in regular learning seamlessly. This encourages children to step outside their comfort zone, and learn new and innovative skills, helping broaden both their skillset and their mindset.<br /><br />
          Appreciating the intellectual dimensions and diversity within our student body, we offer them a wide range of extra and co-curricular activities to choose from. A good mix of sporting, cultural, literary and art events are offered throughout the year for children to explore, and enjoy!<br /><br />
          Our children participate in all the activities with enthusiasm that could rival the largest cricket stadiums. Every competition, presentation, or an event is in fact a showcase of more than the pride and talents – we see teamwork, organizational skills, time management skills, and above all the pride of participation.

          </div>
        <div className="section-image-container extra-activities-image-1-container">
          <img src="/images/extra-activities/Banner 3.JPG" className="section-image"></img>
        </div>
      </div>

      <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Life Skills</span>
            <div className="icons-row">
              <div className="icon-container">
                <img src="/icons/life-skills.png" className="icon-image" />
                <span className="icon-description">Life Skills</span>
              </div>
              <div className="icon-container">
                <img src="/icons/critical-thinking.png" className="icon-image" />
                <span className="icon-description">Critical Thinking</span>
              </div>
              <div className="icon-container">
                <img src="/icons/creative-thinking-and-writing.png" className="icon-image" />
                <span className="icon-description">Creative Thinking &amp; Writing</span>
              </div>
            </div>
            <div className="icons-row">
              <div className="icon-container">
                <img src="/icons/reading-programme.png" className="icon-image" />
                <span className="icon-description">Reading Programme</span>
              </div>
              <div className="icon-container">
                <img src="/icons/effective-communication.png" className="icon-image" />
                <span className="icon-description">Effective Communication</span>
              </div>
              <div className="icon-container">
                <img src="/icons/funtoot.png" className="icon-image" />
                <span className="icon-description">Funtoot Online Math Programme</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container">
                <img src="/icons/life-skills.png" className="icon-image" />
                <span className="icon-description">Life Skills</span>
              </div>
              <div className="icon-container">
                <img src="/icons/critical-thinking.png" className="icon-image" />
                <span className="icon-description">Critical Thinking</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container">
                <img src="/icons/creative-thinking-and-writing.png" className="icon-image" />
                <span className="icon-description">Creative Thinking &amp; Writing</span>
              </div>
              <div className="icon-container">
                <img src="/icons/reading-programme.png" className="icon-image" />
                <span className="icon-description">Reading Programme</span>
              </div>

            </div>
            <div className="icons-row-mobile">
              <div className="icon-container">
                <img src="/icons/effective-communication.png" className="icon-image" />
                <span className="icon-description">Effective Communication</span>
              </div>
              <div className="icon-container">
                <img src="/icons/funtoot.png" className="icon-image" />
                <span className="icon-description">Funtoot Online Math Programme</span>
              </div>

            </div>


          </span>
        </div>
      </div>

      <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Special Clubs</span>
            <div className="icons-row">
              <div className="icon-container">
                <img src="/icons/logical-and-analytical-skills.png" className="icon-image" />
                <span className="icon-description">Logical &amp; Analytical Skills</span>
              </div>
              <div className="icon-container">
                <img src="/icons/spell-fun.png" className="icon-image" />
                <span className="icon-description">Spell Fun</span>
              </div>
              <div className="icon-container">
                <img src="/icons/memory-enhancement.png" className="icon-image" />
                <span className="icon-description">Memory Enhancement</span>
              </div>
            </div>

            <div className="icons-row">
              <div className="icon-container">
                <img src="/icons/general-knowledge.png" className="icon-image" />
                <span className="icon-description">General Knowledge</span>
              </div>
              <div className="icon-container">
                <img src="/icons/arts.png" className="icon-image" />
                <span className="icon-description">Arts</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container">
                <img src="/icons/logical-and-analytical-skills.png" className="icon-image" />
                <span className="icon-description">Logical &amp; Analytical Skills</span>
              </div>
              <div className="icon-container">
                <img src="/icons/spell-fun.png" className="icon-image" />
                <span className="icon-description">Spell Fun</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container">
                <img src="/icons/memory-enhancement.png" className="icon-image" />
                <span className="icon-description">Memory Enhancement</span>
              </div>
              <div className="icon-container">
                <img src="/icons/general-knowledge.png" className="icon-image" />
                <span className="icon-description">General Knowledge</span>
              </div>
            </div>
            <div className="icons-row-mobile">
              <div className="icon-container">
                <img src="/icons/arts.png" className="icon-image" />
                <span className="icon-description">Arts</span>
              </div>
            </div>

          </span>
        </div>
      </div>

      <div className="curvy-container">
        <div className="curvy-image-1-container">
          <img src="/images/extra-activities/_MG_9798.JPG" className="curvy-image-1 leadership-curvy-image-1" />
        </div>
        <div className="curvy-image-2-container">
          <img src="/images/extra-activities/Banner 1.JPG" className="curvy-image-2 leadership-curvy-image-2" />
        </div>
        <div className="curvy-image-3-container">
          <img src="/images/extra-activities/_MG_0947.JPG" className="curvy-image-3 our-story-curvy-image-3" />
        </div> 
      </div>

      {/* <div className="curvy-container new">
        {/* CAROUSEL - 1 
        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/extra-activities/Banner 1.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/extra-activities/_MG_0947.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(6) }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/extra-activities/_MG_2147.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 2 

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/extra-activities/_MG_0947.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/extra-activities/_MG_2147.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/extra-activities/_MG_2301.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 3 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/extra-activities/_MG_2147.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/extra-activities/_MG_2301.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/extra-activities/_MG_2402.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 4 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/extra-activities/_MG_2301.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/extra-activities/_MG_2402.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/extra-activities/_MG_2685.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 5 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/extra-activities/_MG_2402.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/extra-activities/_MG_2685.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/extra-activities/_MG_9798.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 6 


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/extra-activities/_MG_2685.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/extra-activities/_MG_9798.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/extra-activities/Banner 1.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 7 

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/extra-activities/_MG_9798.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/extra-activities/Banner 1.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(0) }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/extra-activities/_MG_0947.JPG" className="sports-image-disabled"></img>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default ExtraActivities;
