import React, { useState, useEffect } from 'react';
import "./learning-activities.css";

function LearningActivitiesPlaygroupNarsingi() {

  return (
    <div className="extra-activities">
      <div className="cover">
        <img src="/playgroup-images/learning/Learning Banner-min.jpg" className="cover-image extra-activities-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Learning at FKS</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/thematic-learning" className="a-nav-link">Thematic Learning Environment</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/learning-framework" className="a-nav-link ">Learning Framework</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/learning-activities" className="a-nav-link a-nav-item-selected">Learning Activities</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content">
          <span className="title">LEARNING ACTIVITIES</span>
          Early years have multiple goals for learning,  like getting ready for writing, building vocabulary, use of materials, fine motor skills, eye-hand coordination, preparing to focus on learning, managing to work in groups and sharing resources while taking turns. <br/><br/> The learning activities thus ensure that all of these goals for learning are achieved gently, regularly and while in play.  They range from  storytelling , to songs, organised games, and group activities, all of this while resource managing and partnering with peers, as part of their everyday routine.
          </div>
        <div className="section-image-container extra-activities-image-1-container">
          <img src="/playgroup-images/learning/5-min.jpg" className="section-image learning-activities-img"></img>
        </div>
      </div>

      <div className="section">
      <div className="section-image-container">
          <img src="/playgroup-images/learning/3-min.jpg" className="section-image learning-activities-section-image-2"></img>
        </div>
        <div className="section-content">
        These learning activities provide opportunities to help the child to get to be a  learner while  independent in taking care of themselves as people. The teachers help them in this process by scaffolding and guiding them to learn while making mistakes. <br/><br/>From learning to writing strokes to form play where they make alphabets from various sensorial materials, slowly and without pressure the child learns to write correctly. <br/><br/>These kinds of activities ensure that all kinds of learners and learning styles are taken care of. By such time, children are confident with reading, writing and arithmetic skills apart from a wide exposure to various concepts and topic modules. They get ready to embark their journey into Primary school.
          </div>
      </div>

    </div>
  );
}

export default LearningActivitiesPlaygroupNarsingi;
