import React from "react"
import { useState, useEffect } from "react";
import "./footer.css"

function Footer() {

    // useEffect(() => {
    //     const existingScript = document.getElementById("googleMaps");

    //     if (!existingScript) {
    //         const script = document.createElement("script");
    //         script.src =
    //             "https://maps.googleapis.com/maps/api/js?key=AIzaSyCr7Xc7zyE1sOBe3GmLouPlp6P1_KrI9so&libraries=places";
    //         script.id = "googleMaps";
    //         script.async = true;

    //         document.body.appendChild(script);

    //         script.onload = () => {
    //             initMap();
    //         };
    //     }

    //     if (existingScript) initMap();
    // }, []);


    // function initMap() {
    //     var google = window.google;
    //     const myLatLng = { lat: 17.405980251143284, lng: 78.34963433422631 };
    //     const map = new google.maps.Map(document.getElementById("map1"), {
    //         zoom: 14,
    //         center: myLatLng
    //     });
    //     new google.maps.Marker({
    //         position: myLatLng,
    //         map,
    //         title: "The Future Kids School"
    //     });
    // }


    function contactPopup() {
        document.getElementById("contact-popup").style.display = "flex";
    }

    return (
        <div className="main-footer-combo">
            <div className="footer-container">
                <div className="footer-area-1">
                    <div className="footer-content-container">
                        <label className="footer-contact-us-text"><b>The Future Kid's School</b></label>

                        <label className="footer-contact-us-sub-text">Puppal Guda, Rajendranagar Mandal, RR Dist, Hyderabad, Telangana 500075</label>
                        <br />
                        <a href="tel:+91 9959 319 742" className="footer-contact-us-sub-text"><img src="/icons8-phone-24.png" className="footer-cta-icons" /> +91 9959 319 742</a>
                        <a href="tel:+91 9959 319 742" className="footer-contact-us-sub-text"><img src="/icons8-phone-24.png" className="footer-cta-icons" /> +91 9154 219 742</a>
                        <br />
                        <a href="mailto:connect@thefks.in" className="footer-contact-us-sub-text"><img src="/icons8-mail-50.png" className="footer-cta-icons" />connect@thefks.in</a>
                        <a href="mailto:admissions@thefks.in" className="footer-contact-us-sub-text"><img src="/icons8-mail-50.png" className="footer-cta-icons" />admissions@thefks.in</a>
                    </div>
                </div>
                <div className="footer-area-2">
                    <div className="footer-content-container-2 align-top-main-campus">
                        <div className="footer-content-container-2-left">
                            <a href="/main-campus" className="footer-contact-us-text2">Home</a>
                            <a href="/main-campus/connect" className="footer-contact-us-text2 ">Admissions</a>
                            <a href="/main-campus/nutrition" className="footer-contact-us-text2 ">Nutrition</a>
                            <a href="/main-campus/campus" className="footer-contact-us-text2 ">Campus</a>
                        </div>
                        <div className="footer-content-container-2-right">
                            <a href="/privacy-policy" className="footer-contact-us-text2 ">Privacy Policy</a>
                            <a href="/" className="footer-contact-us-text2">Sitemap</a>
                        </div>
                    </div>
                </div>
                <div className="footer-area-3">
                    <img className="footer-logo" src="/fks-logo.png" />
                </div>
            </div>
            <div className="footer-copyright-container">
                <label className="footer-copyright-text"> &copy; 2021 The Future Kids School.</label>
            </div>
        </div>
    )
}

export default Footer