import React,{ useState, useEffect } from 'react';
import "./nutrition.css";

function NutritionPlaygroupNarsingi() {

  const [selected, setSelected] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
      document.getElementsByClassName('sports-carousel-item')[i].style.display = "none";
    }
    document.getElementsByClassName('sports-carousel-item')[selected].style.display = "flex";
  }, [selected])

  function carouselNext() {
    for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
      if (selected == 7) {
        break;
      }
      document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
      document.getElementsByClassName('sports-carousel-item')[selected + 1].style.display = "flex";
      setSelected(selected + 1)

    }
  }

  function carouselPrev() {
    for (var i = 0; i < document.getElementsByClassName('sports-carousel-item').length; i++) {
      if (selected == 0) {
        break;
      }
      document.getElementsByClassName('sports-carousel-item')[selected].style.display = "none";
      document.getElementsByClassName('sports-carousel-item')[selected - 1].style.display = "flex";
      setSelected(selected - 1)
    }
  }


  /*MOBILE GALLERY LOGIC */

  const [selectedImg, setSelectedImg] = useState(0)

  useEffect(() => {
    for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
      document.getElementsByClassName('mobile-picture-gallery')[i].style.display = "none";
    }
    document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "flex";
  }, [selectedImg])

  function mobCarouselNext() {
    for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
      if (selectedImg == 7) {
        break;
      }
      document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
      document.getElementsByClassName('mobile-picture-gallery')[selectedImg + 1].style.display = "flex";
      setSelectedImg(selectedImg + 1)

    }
  }

  function mobCarouselPrev() {
    for (var i = 0; i < document.getElementsByClassName('mobile-picture-gallery').length; i++) {
      if (selectedImg == 0) {
        break;
      }
      document.getElementsByClassName('mobile-picture-gallery')[selectedImg].style.display = "none";
      document.getElementsByClassName('mobile-picture-gallery')[selectedImg - 1].style.display = "flex";
      setSelectedImg(selectedImg - 1)
    }
  }

  return (
    <div className="nutrition">
      <div className="cover">
        <img src="/images/nutrition/Idli.jpeg" className="cover-image nutrition-cover-image" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">About Us</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/our-story" className="a-nav-link">Our Story</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/vision-and-mission" className="a-nav-link">FKS Perspective - Philosophy, Vision &amp; Mission</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/fks-force" className="a-nav-link ">FKS Force</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/nutrition" className="a-nav-link a-nav-item-selected">Nutrition</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content text-align-center">
          <span className="title">NUTRITION</span>
          The objective at FKS is to create a global citizen who can adapt, someone who can thrive in any situation. Kids tend to get finicky and picky often, especially with food. The FKS children come from all strata of society and cultures. Their food habits are varied. Shailaja wanted to discourage these differences and convinced parents of the need to have a common kitchen for children.<br /><br />
          Eating easily and commonly available food helps in appreciating all kinds of tastes and eating the same food together gives a sense of community.<br /><br />
         <b> Some of the favourites from the FKS Kitchen: </b><br/><br/>
         Idly with Chutney| Pongal | Upma with Chutney | Sandwiches | Cornflakes | Pav Bhaji | Noodles | Pasta | Festival Specials. <br/><br/>
         Lunch Meals - Pulao | Sambar rice | Roti Chole curry | <br/><br/>
         Our students receive well-planned, nourishing meals throughout the day. The menu is designed with the idea that a growing child, a picky palette and anxious parents are all to be taken very seriously. A well-balanced diet can also be tasty and “yummy”: proof of pudding that Mrs. Rao presents on a daily basis. In fact, her tasters are student volunteers and she takes suggestions and cooking help from the senior graders as she plans the daily menu.<br/><br/>

        </div>
        <div className="section-image-container nutrition-image-1-container mobile-margin">
          <img src="/images/nutrition/Food-image.jpeg" className="section-image nutrition-image-1"></img>
        </div>
      </div>

      <div className="curvy-container new">
        {/* CAROUSEL - 1 */}
        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/Food gallery.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/_MG_7096.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container ">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(7) }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/_MG_7007.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 2 */}

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/_MG_7096.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/_MG_7007.JPG" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 3 */}


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/_MG_7007.JPG" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/IMG_20160311_100933.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 4 */}


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/IMG_20160311_100933.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/Food.jpeg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 5 */}


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/IMG_20160311_100933.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/Food.jpeg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/IMG_20160311_102424.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 6 */}


        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/Food.jpeg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/IMG_20160311_102424.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 7 */}

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/IMG_20160311_102424.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselNext() }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/Food gallery.jpg" className="sports-image-disabled"></img>
          </div>
        </div>

        {/* CAROUSEL - 8 */}

        <div className="sports-carousel-item">
          <div className="sports-carousel-item-left">
            <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="sports-image-disabled"></img>
          </div>
          <div className="sports-carousel-item-center">
            <img src="/images/nutrition/Food gallery.jpg" className="sports-image-focus"></img>
            <div className="sports-carousel-buttons-container">
              <img src="/icons8-left-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); carouselPrev() }} />
              <img src="/icons8-right-64.png" className="sports-carousel-icons" onClick={e => { e.preventDefault(); setSelected(0) }} />
            </div>
          </div>
          <div className="sports-carousel-item-right">
            <img src="/images/nutrition/_MG_7096.JPG" className="sports-image-disabled"></img>
          </div>
        </div>

      </div>





      {/* MOBILE CAROUSEL BEGIN */}

      {/* SCREEN 1*/}

      <div className="mobile-picture-gallery-container">

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/_MG_7096.JPG" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(7) }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/nutrition/_MG_7096.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/nutrition/_MG_7007.JPG" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/nutrition/IMG_20160311_100933.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 2*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/_MG_7007.JPG" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/nutrition/_MG_7096.JPG" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/nutrition/_MG_7007.JPG" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/nutrition/IMG_20160311_100933.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 3*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/nutrition/_MG_7096.JPG" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/nutrition/_MG_7007.JPG" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/nutrition/IMG_20160311_100933.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
          </div>
        </div>

        {/* SCREEN 4*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/IMG_20160311_100933.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(0) }}>
              <img src="/images/nutrition/_MG_7096.JPG" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(1) }}>
              <img src="/images/nutrition/_MG_7007.JPG" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(2) }}>
              <img src="/images/nutrition/IMG-20191112-WA0164.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(3) }}>
              <img src="/images/nutrition/IMG_20160311_100933.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>

        {/* SCREEN 5*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/Food.jpeg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/nutrition/Food.jpeg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/nutrition/IMG_20160311_102424.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/nutrition/Food gallery.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 6*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/IMG_20160311_102424.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/nutrition/Food.jpeg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/nutrition/IMG_20160311_102424.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/nutrition/Food gallery.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 7*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselNext() }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/nutrition/Food.jpeg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/nutrition/IMG_20160311_102424.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/nutrition/Food gallery.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
          </div>
        </div>

        {/* SCREEN 8*/}

        <div className="mobile-picture-gallery">
          <div className="mobile-gallery-focus-image-container">
            <img src="/images/nutrition/Food gallery.jpg" className="mobile-picture-gallery-focus-image" />
            <div className="mobile-gallery-button-container">
              <img src="/icons8-left-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); mobCarouselPrev() }} />
              <img src="/icons8-right-64.png" className="mobile-gallery-icons" onClick={e => { e.preventDefault(); setSelectedImg(0) }} />
            </div>
          </div>
          <div className="mobile-gallery-disabled-image-container">
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(4) }}>
              <img src="/images/nutrition/Food.jpeg" className="mobile-picture-gallery-disabled-image  " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(5) }}>
              <img src="/images/nutrition/IMG_20160311_102424.jpg" className="mobile-picture-gallery-disabled-image" />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(6) }}>
              <img src="/images/nutrition/2012-06-29 11.55.36.jpg" className="mobile-picture-gallery-disabled-image " />
            </div>
            <div className="mobile-gallery-preview-container" onClick={e => { e.preventDefault(); setSelectedImg(7) }}>
              <img src="/images/nutrition/Food gallery.jpg" className="mobile-picture-gallery-disabled-image gallery-image-selected" />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default NutritionPlaygroupNarsingi;
