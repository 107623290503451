import React, { useState, useEffect } from 'react';
import "./learning-framework.css";

function LearningFrameworkP() {

  return (
    <div className="pedagogy">
      <div className="cover">
        <img src="/playgroup-images/learning/Learning Banner-min.jpg" className="cover-image pedagogy-cover thematic-learning-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Learning at FKS</span>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/thematic-learning" className="a-nav-link">Thematic Learning Environment</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/learning-framework" className="a-nav-link  a-nav-item-selected">Learning Framework</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-jubileehills/learning-activities" className="a-nav-link ">Learning Activities</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content text-align-center">
          <span className="content-text ">
          <span className="title">LEARNING FRAMEWORK</span>
            The mentor and the mentee walk hand in hand in the immersive  learning environment. Children are spontaneous, spirited --- it is imperative to make sure the learning environment nurtures and supports this innate  base of our being.<br /><br />

          The individual flourishes and builds oneself in a stimulating, fertile and seamless learning framework.<br /><br />

          The learning modules are planned to cater to individual needs while ensuring the enhancement of the entire group. In short, the children are their individual self while they are a part of the whole.
          </span>
        </div>
        <div className="section-image-container pedagogy-image-1-container">
          <img src="/playgroup-images/learning/1-min.jpg" className="section-image pg-learning-frame-img1"></img>
        </div>
      </div>

      <div className="section">
      <div className="section-image-container pedagogy-image-1-container">
          <img src="/playgroup-images/learning/6-min.jpg" className="section-image pg-learning-framework-img2"></img>
        </div>
        <div className="section-content mobile-margin">
          <span className="content-text">
            <b>The tenets of our learning programs from playgroup through high school.</b><br /><br />
          1. Pre-activity (check what the child already knows) <br /><br />
          2. Introduction (initiating the topic from where the child does not know) <br /><br />
          3. Exploration (gaining deeper understanding by experiential learning) <br /><br />
          4. Reiteration ( recall, repeat, remember) <br /><br />
          5. Application (using the understanding in different situations)<br /><br />
          </span>
        </div>
      </div>

      <div className="section">
        <div className="section-content pedagogy-section-content">
          <span className="content-text ">
            Foundation of FKS Pedagogy are the 3Es. Environment, Exploration and Experience
            <div className="pedagogy-3e-container">
              <div className="pedagogy-3e-image-container">
                <img src="/icons/environment.png" className="pedagogy-3e-image" />
              </div>
              <div className="pedagogy-3e-content">
                <span className="pedagogy-3e-title">Environment</span>
                FKS is a space which would bend backwards for the child. An environment where a child will find her/him self  to be their own best version.  In this environment, spaces are safe for learning and growing, looking inward as much as outward just like their learning. They are rugged at the same time, giving grounded-ness to growing children.
              </div>
            </div>
            <div className="pedagogy-3e-container">
              <div className="pedagogy-3e-image-container">
                <img src="/icons/explore.png" className="pedagogy-3e-image" />
              </div>
              <div className="pedagogy-3e-content">
                <span className="pedagogy-3e-title">Exploration</span>
                The environment allows students to explore it by themselves. They learn to accept, get to voice their opinion, and make choices about their everyday situations. Hands-on learning helps students explore who they are and who they are becoming in the process.
FKS does not set any benchmark, no preconceived notions but gives them an environment that is safe and non-judgmental.

              </div>
            </div>
            <div className="pedagogy-3e-container">
              <div className="pedagogy-3e-image-container">
                <img src="/icons/experience.png" className="pedagogy-3e-image" />
              </div>
              <div className="pedagogy-3e-content">
                <span className="pedagogy-3e-title">Experience</span>
                Our effort is to encourage each person to believe, love and stand up for themself. The FKS experience makes students believe that everybody is equal and deserves equal opportunities. As kids, they learn to appreciate one and all. Students collectively experience oneness, equality, and respect from and with their classmates, teachers, and didi-bhaiyas.
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LearningFrameworkP;
