import React, { useState, useEffect } from "react";
import "./footer.css";

function FooterPlaygroupNarsingi() {
  // useEffect(() => {
  //     const existingScript = document.getElementById("googleMaps");

  //     if (!existingScript) {
  //         const script = document.createElement("script");
  //         script.src =
  //             "https://maps.googleapis.com/maps/api/js?key=AIzaSyCr7Xc7zyE1sOBe3GmLouPlp6P1_KrI9so&libraries=places";
  //         script.id = "googleMaps";
  //         script.async = true;

  //         document.body.appendChild(script);

  //         script.onload = () => {
  //             initMap();
  //         };
  //     }

  //     if (existingScript) initMap();
  // }, []);

  // function initMap() {
  //     var google = window.google;
  //     const myLatLng = { lat: 17.405980251143284, lng: 78.34963433422631 };
  //     const map = new google.maps.Map(document.getElementById("map1"), {
  //         zoom: 14,
  //         center: myLatLng
  //     });
  //     new google.maps.Marker({
  //         position: myLatLng,
  //         map,
  //         title: "The Future Kids School"
  //     });
  // }

  function contactPopup() {
    document.getElementById("contact-popup").style.display = "flex";
  }

  return (
    <div className="main-footer-combo">
      <div className="footer-container">
        <div className="footer-area-1">
          <div className="footer-content-container">
            <label className="footer-contact-us-text">
              <b>The Future Kid's School</b>
            </label>

            <label className="footer-contact-us-sub-text">
              H. No. 2-113/4/1 &amp; 2-113/4/2, Venkateswara kuteer,
              Manchirevula, Narsingi , Ranga Reddy District. Pin -500075
            </label>
            <br />
            <span>
              <a
                href="tel:+91 73311 17805"
                className="footer-contact-us-sub-text"
              >
                <img src="/icons8-phone-24.png" className="footer-cta-icons" />
                +91 73311 17805
              </a>
            </span>
            <a
              href="mailto:connect@thefkspg.in"
              className="footer-contact-us-sub-text"
            >
              <img src="/icons8-mail-50.png" className="footer-cta-icons" />
              connect@thefksnsg.in
            </a>
          </div>
        </div>
        <div className="footer-area-2">
          <div className="footer-content-container-2">
            <div className="footer-content-container-2-left">
              <a href="/playgroup-narsingi" className="footer-contact-us-text2">
                Home
              </a>
              <a
                href="/playgroup-narsingi/connect"
                className="footer-contact-us-text2 "
              >
                Admissions
              </a>
              <a
                href="/playgroup-narsingi/well-being"
                className="footer-contact-us-text2 "
              >
                Well Being
              </a>
              <a
                href="/playgroup-narsingi/campus"
                className="footer-contact-us-text2 "
              >
                Campus
              </a>
            </div>
            <div className="footer-content-container-2-right">
              <a href="/privacy-policy" className="footer-contact-us-text2 ">
                Privacy Policy
              </a>
              <a href="/" className="footer-contact-us-text2">
                Sitemap
              </a>
            </div>
          </div>
        </div>
        <div className="footer-area-3">
          <img className="footer-logo" src="/fks-logo.png" />
        </div>
      </div>
      <div className="footer-copyright-container">
        <label className="footer-copyright-text">
          {" "}
          &copy; 2021 The Future Kids School.
        </label>
      </div>
    </div>
  );
}

export default FooterPlaygroupNarsingi;
