import "./thematic-learning.css";
import React from 'react';

function ThematicLearningPlaygroupNarsingi() {
  return (
    <div className="board-and-curriculum">
      <div className="cover">
        <img src="/playgroup-images/learning/Learning Banner-min.jpg" className="cover-image board-and-curriculum-cover thematic-learning-cover" />
        <span className="a-nav">
          <span className="a-nav-item">
            <span className="a-nav-heading">Learning at FKS</span>
          </span>
          <span className="a-nav-item">
            <a href="/board-and-curriculum" className="a-nav-link a-nav-item-selected ">Thematic Learning Environment</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/learning-framework" className="a-nav-link pg-mob-nav-header">Learning Framework</a>
          </span>
          <span className="a-nav-item">
            <a href="/playgroup-narsingi/learning-activities" className="a-nav-link pg-mob-nav-header">Learning Activities</a>
          </span>
        </span>
      </div>

      <div className="section">
        <div className="section-content text-align-center">
          <span className="title">THEMATIC LEARNING</span>
          The preschool years are the point where the child begins to explore itself. The child metamorphoses itself to be a part of a bigger world away from the confines of its home. <br /><br />

          We lay foundations for their future learning path through the three years of play school.  We  work at enhancing their skills through a multi-sensory approach in a thematic learning environment. It is very important that we allow this process to unfold with as little interference as possible. Thus, the environment created is -- safe, offering equal opportunities, it is supportive and nurturing.


        </div>
        <div className="section-image-container">
          <img src="/playgroup-images/learning/2-min.jpg" className="section-image"></img>
        </div>
      </div>

      {/* <div className="section">
        <div className="section-image-container">
          <img src="/images/board-and-curriculum/Board Banner.jpg" className="section-image"></img>
        </div>
        <div className="section-content">
          FKS has adopted the Indian School Certificate (ISC) syllabus for grades 11 and 12. On careful assessment, FKS believes that ISC has an in-depth syllabus. This coupled with innovative methods are a great advantage for students.

        </div>
      </div> */}



      <div className="section">
        <div className="section-image-container">
          <img src="/playgroup-images/learning/10-min.jpg" className="section-image thematic-learning-img2"></img>
        </div>
        <div className="section-content text-align-center">
          The  comprehensive programs integrate learning and developmental goals in themes which range from the self and extending to the world at large. <br /><br />

          Our programs focus at enhancing large and fine motor skills, language development, cognitive skills, social and emotional development.  The programs cater to the curious and inquisitive nature of the pre-schooler. The multisensory approach keeps them involved, enthused and motivated. <br /><br />
        </div>

      </div>



      <div className="section">
        <div className="section-content text-align-center">
          We  believe in an inclusive approach. The Special ED team constantly enhances the programme. The faculty designs an intensive remedial program that is tailor made to suit the <br />
          <ul>
            <li><span className="pg-thematic-learn-list">Diverse learning needs</span></li>
            <li><span className="pg-thematic-learn-list">Consolidate their knowledge</span></li>
            <li><span className="pg-thematic-learn-list">Strengthen their confidence</span></li>
            <li><span className="pg-thematic-learn-list">Enhance the effectiveness of learning</span></li>
          </ul>
          <br />

          <b>Our goal at the end of preschool years:</b><br />

          <ul>
            <li><span className="pg-thematic-learn-list">They have the basic skills of reading, writing and numbers.</span></li>
            <li><span className="pg-thematic-learn-list">Make the child school ready</span></li>
            <li><span className="pg-thematic-learn-list">Weaned off the home for a long duration</span></li>
          </ul>
        </div>
        <div className="section-image-container board-and-curriculum-image-3-container">
          <img src="/playgroup-images/learning/8-min.jpg" className="section-image thematic-learning-section-image-3"></img>
        </div>
      </div>



      {/* <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Life Skills</span>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/life-skills.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Life Skills</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/critical-thinking.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Critical Thinking</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/creative-thinking-and-writing.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Creative Thinking &amp; Writing</span>
              </div>
            </div>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/reading-programme.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Reading Programme</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/effective-communication.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Effective Communication</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/funtoot.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Funtoot Online Math Programme</span>
              </div>
            </div>
          </span>
        </div>
      </div> */}



      {/* <div className="section">
        <div className="section-content theatre-and-drama-icon-section">
          <span className="content-text">
            <span className="title">Special Clubs</span>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/logical-and-analytical-skills.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Logical &amp; Analytical Skills</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/spell-fun.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Spell Fun</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/memory-enhancement.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Memory Enhancement</span>
              </div>
            </div>
            <div className="theatre-and-drama-icon-list">
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/general-knowledge.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">General Knowledge</span>
              </div>
              <div className="theatre-and-drama-icon-container board-and-curriculum-icon-container">
                <img src="./icons/arts.png" className="theatre-and-drama-icon-image" />
                <span className="theatre-and-drama-icon-description">Arts</span>
              </div>
            </div>
          </span>
        </div>
      </div> */}

    </div>
  );
}

export default ThematicLearningPlaygroupNarsingi;
